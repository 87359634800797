<div class="main-content">
    <div class="header">
        <a (click)="viewClientList()">Lista de Usuários</a>
        <i class="material-icons">arrow_forward_ios</i>
        <span>{{client_name}}</span>
    </div>
    
    <div class="tabs">
        <div class="tabs-header" *ngIf="client">
            <button *ngIf="client.childProfile == 'Cliente de Parceiro'" 
                [ngClass]="client.childProfile != 'Cliente de Parceiro' ? '' : 'active'" 
                class="btn btn-tab" (click)="changeTab($event)" id="cams">
                Câmeras e detecções
            </button>
            <button *ngIf="client.childProfile == 'Cliente de Parceiro'" class="btn btn-tab" (click)="changeTab($event)" id="alerts">
                Alertas de teste
            </button>
            <button *ngIf="client.childProfile == 'Cliente de Parceiro'" class="btn btn-tab" (click)="changeTab($event)" id="info">
                Dados do cliente
            </button>
            <button *ngIf="client.childProfile == 'Cliente de Parceiro'" class="btn btn-tab" (click)="changeTab($event)" id="guests">
                Usuários da conta
            </button>
            <button *ngIf="client.childProfile != 'Cliente de Parceiro'" 
                [ngClass]="client.childProfile != 'Cliente de Parceiro' ? 'active' : ''"
                class="btn btn-tab" (click)="changeTab($event)" id="info">
                Dados do convidado
            </button>
        </div>
        <div class="tabs-content" *ngIf="!loading">
            <!-- <app-camera-detections-stock [inventory]="inventory" [plans]="plans" [hidden]="activeTab != 'cams'"></app-camera-detections-stock>
                <app-camera-kits-stock [inventory]="inventory" [plans]="plans" [hidden]="activeTab != 'kits'"></app-camera-kits-stock> -->
                <app-camera-list *ngIf="activeTab === 'cams'"></app-camera-list>
                <app-camera-view-group *ngIf="activeTab === 'view_group'" context="client_view"></app-camera-view-group>
                <app-base-detection *ngIf="activeTab === 'alerts'"></app-base-detection>
                <app-client-info *ngIf="activeTab === 'info'"></app-client-info>
                <app-user-client-guests context="client-view" *ngIf="activeTab === 'guests'"></app-user-client-guests>
        </div>
        <div class="tabs-content" *ngIf="loading">
            <div class="center-spinner">
                <span nz-icon nzType="loading" nzTheme="outline" style="font-size:50px"></span>
            </div>
        </div>
    </div>
</div>
