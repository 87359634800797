import { Store } from '@ngrx/store';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { AppState } from 'app/store/model';
import { environment } from 'environments/environment';
import { Subscription, of } from 'rxjs';
import { Camera, GroupCamera, PaginationCameras, Plan, SortColumnCamera } from '../models';
import { CameraActions } from '../Services/actions';
import { AnalyticActions } from 'app/analytics/Services/actions';
import * as AnalyticsModels from 'app/analytics/models';
import { Router } from '@angular/router';
import { SharedService } from 'app/Shared/Services/shared.service';
import { UntypedFormControl } from '@angular/forms';
import { ImageService } from 'app/Shared/Services/image.service';

@Component({
    selector: 'app-camera-view-group',
    templateUrl: './camera-view-group.component.html',
    styleUrls: ['./camera-view-group.component.scss']
})
export class CameraViewGroupComponent implements OnInit, OnDestroy {
    readonly groupCameraID$ = this.store.select((state: AppState) => state.camera.groupCameraID);
    readonly plans$ = this.store.select((state: AppState) => state.camera.plans);

    @Input() context: string;

    groupCamerasSub: Subscription;
    plansSub: Subscription;

    groupData: GroupCamera[];
    planList: Plan[];
    debounceSearch: ReturnType<typeof setTimeout>;

    hasCameras: boolean;
    searchInputControl = new UntypedFormControl('', []);
    sortBy: SortColumnCamera[];
    sortOptions = [
        {
            id: 1,
            text: 'Recentes',
            value: { 'column': 'cam.registered_at', 'sort': 'DESC' }
        },
        {
            id: 2,
            text: 'Online',
            value: { 'column': 'cam.status', 'sort': 'DESC' }
        },
        {
            id: 3,
            text: 'Offline',
            value: { 'column': 'cam.status', 'sort': 'ASC' }
        },
        {
            id: 4,
            text: 'Ordem Alfabética',
            value: { 'column': 'cam.alias', 'sort': 'ASC' }
        }
    ];

    actives: boolean[];
    sortingTable: boolean;
    cameras: Camera[];
    loading = true;
    previousRouterName = localStorage.getItem('previousRouterName');
    pathPreviousRouter = localStorage.getItem('previousRouter');
    groupName: string;

    camLimit = 50;
    pageIndex = 1;

    actualGroup: number;
    allPagesGroup = 0;
    totalAmountGroup = 0;
    totalAmountAnalytic = 0;
    actualAmountGroup: number;

    groupId: number;
    integrated = false;

    constructor(private router: Router, private readonly store: Store<AppState>, private service: SharedService, private IS: ImageService) { }

    ngOnInit(): void {
        localStorage.setItem('contextAnalytics', 'groupView');
        this.groupName = localStorage.getItem('groupName');
        this.groupId = parseInt(localStorage.getItem('groupId'), 10);
        // this.store.dispatch(CameraActions.clear_group_cameras());
        this.dispatchGroupData();
        this.store.dispatch(CameraActions.get_plans({associateId: environment.associateId}));

        this.getData();
    }

    ngOnDestroy(): void {
        if (this.plansSub) {
            this.plansSub.unsubscribe();
        }
        if (this.groupCamerasSub) {
            this.groupCamerasSub.unsubscribe();
        }
        this.store.dispatch(CameraActions.cleanup());
        this.store.dispatch(CameraActions.open_view_group_clear());
        localStorage.removeItem('contextAnalytics');
    }

    dispatchGroupData(): void {
        this.cameras = [];
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: localStorage.getItem('sub'),
            type_request: 'data',
            group_id: this.groupId,
            page: this.pageIndex,
            limit: this.camLimit
        }));
    }

    getData(): void {
        this.plansSub = this.plans$.subscribe((plans) => {
            this.groupCamerasSub = this.groupCameraID$.subscribe((group) => {
                if (plans) {
                    this.planList = plans;
                }

                if (group && group.length > 0) {
                    this.groupData = group;
                    const camGroups = this.groupData[0].cameras_info_group as PaginationCameras;
                    this.totalAmountAnalytic = camGroups.total_analytic;
                    this.totalAmountGroup = camGroups.total;
                    this.allPagesGroup = camGroups.pages;
                    this.actualGroup = camGroups.actual;
                    this.actualAmountGroup = this.actualGroup < this.allPagesGroup ? this.camLimit * this.actualGroup : this.totalAmountGroup;
                    this.cameras = this.groupData[0].cameras_info_group.cameras;
                    this.cameras.forEach(async c => {
                        this.IS.saveCacheThumbs(c.thumbnail);
                        c.cache_thumb = of(
                            await this.IS.getCSSBackgroundImageURL(c.thumbnail)
                        );
                    });
                    if (this.cameras.length !== 0) {
                        this.hasCameras = true;
                        this.loading = false;
                    }
                    else {
                        setTimeout(() => {
                            this.hasCameras = false;
                            this.loading = false;
                        }, 5000);
                    }

                }
            });
        });

    }

    returnPlanFromId(id: number): Plan {
        if (this.planList) {
            return this.planList.find(element => element.id === id);
        } else {
            return { resolution: { alias: '', width: '0', height: '0' }, days_storage: 0 } as Plan;
        }
    }

    returnInfoAnalytic(): number {
        let sum = 0;
        if (this.groupData) {
            sum = this.groupData[0].cameras_info_group.cameras.reduce((acc, element) => {
                return acc + element.analytics;
            }, 0);
        }

        return sum;
    }

    changeSort(event) {
        this.sortBy = [this.sortOptions.find(x => x.id == event[0]).value] as SortColumnCamera[];
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: localStorage.getItem('sub'),
            type_request: 'data',
            group_id: this.groupId,
            str_filter: null,
            cam_stat_filter: null,
            limit: this.camLimit,
            page: this.pageIndex,
            clients_id: null,
            sort_by: this.sortBy
        }));
    }

    changeSortedColumn(id = 1) {
        this.sortingTable = true;
        let sortType;
        switch (id) {
            case 1:
                sortType = 'timestamp';
                break;
            case 2:
                sortType = 'status_ativo';
                break;
            case 3:
                sortType = 'status_inativo';
                break;
            case 4:
                sortType = 'nome';
                break;
        }
        const sortState: Sort = { active: sortType, direction: 'asc' };
        const isAsc = sortState.direction === 'asc';
        const isDesc = sortState.direction === 'desc';

        let status = '';
        if (sortState.active === 'status_ativo') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'asc';
        }
        if (sortState.active === 'status_inativo') {
            status = sortState.active;
            sortState.active = 'status';
            sortState.direction = 'desc';
        }
        this.cameras = this.cameras.sort((a, b) => {
            if (status == 'status_ativo') {
                return this.regularCompare(a.active, b.active, isDesc);

            } else if (status == 'status_inativo') {
                return this.regularCompare(a.active, b.active, isAsc);

            } else if (sortState.active == 'nome') {
                return this.regularCompare(a.alias.toUpperCase(), b.alias.toUpperCase(), isAsc);

            } else {
                return this.dateCompare(a.last_modify, b.last_modify, isDesc);
            }
        });
        if (this.cameras) {

            if (this.cameras) {
                const actives = [];
                this.cameras.forEach(camera => {
                    actives.push(camera.active);
                });
                this.actives = actives;
            }
        }
        this.sortingTable = false;
    }

    // Compara o nome ou status e ordena corretamente
    regularCompare(a, b, isAsc): number{
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // Compara a data e retorna a ordenação corretamente
    dateCompare(a, b, isAsc): number{
        const dateA = new Date(a).valueOf();
        const dateB = new Date(b).valueOf();
        return (dateA < dateB ? -1 : 1) * (isAsc ? 1 : -1);
    }

    processPlural(amount: number, text: string): string {
        return amount.toString() + ' ' + text + (amount > 1 ? 's' : '');
    }

    openModalAnalytics(cam: Camera) {
        if (cam.created_off == false) {
            if (cam.active == true){
                this.store.dispatch(CameraActions.put_analytic_camera({ payload: cam }));
                this.store.dispatch(AnalyticActions.update_modal_state({ payload: AnalyticsModels.ModalState.List }));
            }
        }
    }

    toPreviousPath() {
        this.router.navigateByUrl(this.pathPreviousRouter);
    }

    returnToList(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([this.router.url]);
    }

    loadingEvent(event: boolean) {
        this.loading = event;
    }

    returnClientName(client_id: number): string {
        return this.groupData[0].clients_info_group.find(element => element.id_user === client_id).name;
    }

    brokenImageHandler(element) {
        element.error = false;
        element.target.src = '../../../assets/img/broken_link.svg';
    }


    viewLiveCamera(cam: Camera) {
        const dataModal = {
            data: {
                camera: cam
            },
            modalHeight: '100%',
            modalMaxHeight: 'fit-content',
            backdropClass: 'lightBackdrop',
            tipoModal: 'live_camera'
        };
        this.service.returnDataToModal(dataModal);
    }

    onPageIndexChange() {
        clearTimeout(this.debounceSearch);
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: localStorage.getItem('sub'),
                type_request: 'data',
                group_id: this.groupId,
                str_filter: null,
                cam_stat_filter: null,
                limit: this.camLimit,
                page: this.pageIndex,
                clients_id: null,
                sort_by: this.sortBy
            }));
            this.loading = true;
        }, 300);
    }

    requestSearch() {
        clearTimeout(this.debounceSearch);
        const query = this.searchInputControl.value;
        this.debounceSearch = setTimeout(() => {
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: localStorage.getItem('sub'),
                type_request: 'data',
                group_id: this.groupId,
                str_filter: query,
                cam_stat_filter: null,
                limit: this.camLimit,
                page: 1,
                clients_id: null,
                sort_by: this.sortBy
            }));
            this.loading = true;
        }, 300);
    }
}
