import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { formatDate, registerLocaleData } from '@angular/common';
import { NavButton, Camera, Video, ListType, PaginationCameras } from '../models';
import { Subscription, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { CameraActions } from '../Services/actions';
import { AppState } from 'app/store/model';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { Client } from 'app/Users/models';
import { UserActions } from 'app/Users/Services/actions';
import { DetectionActions } from 'app/detections/Services/actions';
import { PaginationDetections } from 'app/detections/models';
import { ImageService } from 'app/Shared/Services/image.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import localePtBr from '@angular/common/locales/pt';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';

registerLocaleData(localePtBr);

export class PtBrDateAdapter extends NativeDateAdapter {
    override parse(value: any): Date | null {
        if (typeof value === 'string' && value.indexOf('/') > -1) {
            const str = value.split('/');
            // Handle DD/MM/YYYY
            if (str.length === 3) {
                const day = +str[0];
                const month = +str[1] - 1;
                const year = +str[2];
                return new Date(year, month, day);
            }
        }
        return super.parse(value);
    }

    override format(date: Date, displayFormat: string): string {
        if (displayFormat === 'input') {
        // Format date as DD/MM/YYYY
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${this._to2digit(day)}/${this._to2digit(month)}/${year}`;
        }
        return super.format(date, displayFormat);
    }

    private _to2digit(n: number): string {
        return ('00' + n).slice(-2);
    }
}

@Component({
    selector: 'app-camera-playlist',
    templateUrl: './camera-playlist.component.html',
    styleUrls: ['./camera-playlist.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: PtBrDateAdapter },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }
    ],
})
export class CameraPlaylistComponent implements OnInit, AfterViewInit, OnDestroy {
    readonly playlist$ = this.store.select((state: AppState) => state.camera.playlist);
    readonly camera$ = this.store.select((state: AppState) => state.camera.camera_view);
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly camerasShared$ = this.store.select((state: AppState) => state.camera.cameraShared);
    readonly groupCamera$ = this.store.select((state: AppState) => state.camera.groupCamera);
    readonly clients$ = this.store.select((state: AppState) => state.Users.client_list);
    readonly detections$ = this.store.select((state: AppState) => state.detection.detections);

    rangeForm = new UntypedFormGroup({
        start: new UntypedFormControl(''),
        end: new UntypedFormControl('')
    });
    currentTime = new Date();
    formOrder = ['date', 'timeBegin', 'timeEnd'];
    formIndex = 0;

    getDetectionsCams: Subscription;
    videoSub: Subscription;
    camSub: Subscription;
    camerasSub: Subscription;
    camsSub: Subscription;
    invSub: Subscription;
    groupCamSub: Subscription;

    currentVideo: string;
    searchText: string;
    dateRange = [];
    dateFormat = 'dd/MM/yyyy';
    playlist: any;
    currentCamera: Camera;
    allCameras: Camera[] = undefined;
    playlistLoaded = false;
    camerasLoaded = false;
    loading = false;
    loadingCam: boolean;
    videoLoaded = true;
    videoIndex = 0;
    scrollbarCam: any;
    scrollbar: any;
    alertsInProgressBar;
    selectedCamera = -1;
    previousCameraSelected = -1;
    previousScrollPosition = 0;
    navButtons: NavButton[] = [
    // {
    //   title: 'Anteontem',
    //   status: false
    // },
        {
            title: 'Ontem',
            status: false
        },
        {
            title: 'Hoje',
            status: true
        }
    ];
    activeNav = this.navButtons[0];
    @ViewChild('finalTime') finalTime: ElementRef;
    @ViewChild('initialTime') initialTime: ElementRef;

    // Controladores da tag de video
    video: HTMLVideoElement;
    videoControls: HTMLElement;
    alertsOfVideo: HTMLElement;
    playButton: HTMLElement;
    playbackIcons: NodeListOf<HTMLElement>;
    replay: HTMLElement;
    forward: HTMLElement;
    timeElapsed: HTMLElement;
    duration: HTMLElement;
    progressBar: HTMLProgressElement;
    seek: HTMLInputElement;
    seekToolTip: HTMLElement;
    volumeButton: HTMLButtonElement;
    volumeIcons: NodeListOf<HTMLElement>;
    volumeMute: HTMLElement;
    volumeLow: HTMLElement;
    volumeHigh: HTMLElement;
    volume: HTMLInputElement;
    playbackAnimation: HTMLElement;
    fullScreenButton: HTMLButtonElement;
    videoContainer: HTMLElement;
    fullScreenIcons: NodeListOf<SVGUseElement>;
    pipButton: HTMLButtonElement;
    time: HTMLElement;
    download: HTMLAnchorElement;
    debounceSearch: ReturnType<typeof setTimeout>;
    selected = ['1x'];
    selectedVideo = true;
    speed = 1;
    beginTime;
    endTime;
    beginDate;
    endDate;
    beginDateFormated;
    endDateFormated;
    pageName = 'camera-playlist';
    isDownloading: boolean;
    dateSelected: boolean;
    day: any;
    dayIndex: number;
    cameraName: string;
    sharedCamsList = [];
    sharedCamId = [];

    loadingClients = false;
    loadingAlerts = false;
    isPartner: boolean;
    userSub: string;
    selectedClient: string;
    clientSub: Subscription;
    clientList: Client[];
    clientListSimple: {
        id: string;
        text: string;
    }[];
    reRender = false;
    scrollHeight = 0;

    isAssociate: boolean;
    adminAssociate: boolean;
    @ViewChild('divToScroll', { static: true }) divToScrollRef: HTMLElement;
    scrollCouting = 0;
    showPrevArrow = false;
    showNextArrow = true;
    totalAlertCard: number;

    sum = 1;
    sumGroup = 1;
    camLimit = 9;

    actual: number;
    actualGroup: number;
    allPages = 0;
    allPagesGroup = 0;
    totalAmount = 0;
    totalAmountGroup = 0;
    actualAmount: number;
    actualAmountGroup: number;

    dataToProcessing: any;
    isProcessing: boolean;
    isVideoProcessing: boolean;
    countRequestRecording = 0;

    constructor(private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule, private IS: ImageService, private dateAdapter: DateAdapter<any>) {
        this.dateAdapter.setLocale('pt-BR'); // Set locale globally
    }
    ngOnInit() {
        this.userSub = localStorage.getItem('sub');
        this.getProfile();
        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
        if (this.isPartner || this.isAssociate) {
            this.getClients();
        }
        this.dateRange.push(this.getDay(false));
        this.dateRange.push(this.getDay(true));
        this.video = document.getElementById('video') as HTMLVideoElement;
        const containerCameras = document.querySelector('#containerCameras') as HTMLElement;
        this.store.dispatch(CameraActions.get_cameras({
            user_sub: this.userSub,
            page: this.sum,
            limit: this.camLimit,
            short: true
        }));
        this.store.dispatch(CameraActions.get_invited_cameras({
            user_sub: localStorage.getItem('sub'),
            list_type: ListType.Recording
        }));
        this.camSub = this.camera$.subscribe(cam => {
            this.currentCamera = cam;
        });
        if (!this.isPartner && !this.isAssociate) {
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: this.userSub,
                type_request: 'cameras',
                page: this.sumGroup,
                limit: this.camLimit
            }));
        }
        this.resultCameras();

        this.videoSub = this.playlist$.subscribe(playlist => {
            if (playlist) {
                if (playlist.length > 0) {
                    const actualLengthPlaylist = this.playlist ? this.playlist[this.playlist.length - 1].recordings.length : 0;
                    if (this.isProcessing && actualLengthPlaylist == 0) {
                        setTimeout(() => { this.repeatRetriveVideos(); }, 2000);
                        this.playlist = Object.values(playlist[0]);
                        this.videoIndex = 0;
                        const lastRec = this.playlist[this.playlist.length - 1].recordings[this.playlist[this.playlist.length - 1].recordings.length - 1];
                        this.playlist[this.playlist.length - 1].recordings.push({
                            day_rec: lastRec.day_rec,
                            alarms: 0,
                            media_end_date: this.endDate,
                            media_create_date: lastRec.media_end_date,
                            url: undefined,
                            alerts: []
                        });
                    } else if (this.isProcessing && actualLengthPlaylist > 0) {
                        const newPlaylist = Object.values(playlist[0]);
                        if (newPlaylist[newPlaylist.length - 1].recordings.length >= (actualLengthPlaylist)) {
                            this.playlist = Object.values(playlist[0]);
                            this.isProcessing = false;
                            this.selectDay(null, this.dayIndex);
                            if (this.isVideoProcessing) {
                                this.selectVideo(this.videoIndex);
                            }
                        } else {
                            this.repeatRetriveVideos();
                        }
                    } else {
                        this.playlist = Object.values(playlist[0]);
                        if (this.day) {
                            this.day.recordings = this.playlist[0].recordings;
                        }
                        this.videoIndex = 0;
                    }

                    this.playlistLoaded = true;
                    // this.playlist = Object.values(playlist[0]);
                    this.video.classList.remove('cover-fit');
                    this.video.classList.add('regular-fit');
                } else {
                    this.isProcessing = false;
                    if (this.loading) {
                        this.validationResponse.validationResponseHandler(200, this.pageName, 'not-record', 'cameras.not_record');
                    }
                }
            }
            this.loading = false;
        });
        this.getElements();
        this.hideVideo();
        this.addEvents();
        this.getDetectionsResult();
        this.dateSelected = false;
    }

    resultCameras() {
        this.camSub = this.cameras$.subscribe(cameras => {
            this.camerasLoaded = true;
            this.loadingCam = false;
            if (cameras && cameras.model === 'cameras') {
                const cam: any = cameras.cameras as PaginationCameras;
                this.totalAmount = cam.total;
                this.allPages = cam.pages;
                this.actual = cam.actual;
                this.camLimit = cam.limit;
                this.getUniqueCameras(cam.cameras);
            }
            this.selectCameraChoice();
        });

        this.invSub = this.camerasShared$.subscribe(shareCams => {
            this.camerasLoaded = true;
            this.loadingCam = false;
            if (shareCams && shareCams.length > 0) {
                this.sharedCamsList = shareCams;
                this.getUniqueCameras(shareCams);
            }
            this.selectCameraChoice();
        });

        this.groupCamSub = this.groupCamera$.subscribe(groupCamera => {
            this.camerasLoaded = true;
            this.loadingCam = false;
            if (groupCamera as PaginationCameras) {
                groupCamera = groupCamera as PaginationCameras;
                this.totalAmountGroup = groupCamera.total;
                this.allPagesGroup = groupCamera.pages;
                this.actualGroup = groupCamera.actual;
                this.actualAmountGroup = this.actualGroup < this.allPagesGroup ? this.camLimit * this.actualGroup : this.totalAmountGroup;
                this.getUniqueCameras(groupCamera.cameras);
            }
            this.selectCameraChoice();
        });
    }

    selectCameraChoice() {
        const idCam = localStorage.getItem('idCameraChoice');
        if (idCam && this.allCameras != undefined && this.allCameras.length > 0) {
            const idCams = Number(idCam);
            const returnCamera = this.allCameras.find(camera => camera.id == idCams);
            this.chooseCam(returnCamera);
        }
    }

    ngAfterViewInit() {
        this.beginTime = ('0' + (this.currentTime.getHours() - 1)).slice(-2) + ':' + ('0' + this.currentTime.getMinutes()).slice(-2);
        this.endTime = ('0' + (this.currentTime.getHours())).slice(-2) + ':' + ('0' + this.currentTime.getMinutes()).slice(-2);
    }

    nextScroll(elementHtml: HTMLElement) {
        const alertCard = document.getElementById('alertCard');
        const alertCardSize = alertCard.offsetWidth;
        this.totalAlertCard = alertCardSize + 14;
        this.showPrevArrow = true;
        this.divToScrollRef = elementHtml;
        if (this.scrollCouting < this.day.recordings[this.videoIndex].alerts.length - 3) {
            this.scrollCouting++;
        }
        if (this.scrollCouting == this.day.recordings[this.videoIndex].alerts.length - 3) {
            this.showNextArrow = false;
            elementHtml.scrollTo({ left: ((this.scrollCouting + 1) * this.totalAlertCard), behavior: 'smooth' });
        } else {
            elementHtml.scrollTo({ left: (this.scrollCouting * this.totalAlertCard), behavior: 'smooth' });
        }
    }

    clearScroll() {
        if (this.divToScrollRef) {
            this.divToScrollRef.scrollTo({ left: 0, behavior: 'smooth' });
            this.showPrevArrow = false;
            this.showNextArrow = true;
        }
    }

    previousScroll(elementHtml: HTMLElement) {
        if (this.scrollCouting > 0) {
            this.scrollCouting--;
        }
        this.showNextArrow = true;
        this.showPrevArrow = this.scrollCouting !== 0;
        elementHtml.scrollTo({ left: this.scrollCouting * this.totalAlertCard, behavior: 'smooth' });
    }

    scrollToSelectedCard(elementHtml: HTMLElement, recordings: any, index: number) {
        const alertCard = document.getElementById('alertCard');
        const alertCardSize = alertCard.offsetWidth;
        this.divToScrollRef = elementHtml;
        this.totalAlertCard = alertCardSize + 14;
        if (index >= 2) {
            this.scrollCouting = index - 1;
            this.showPrevArrow = true;
        } else {
            this.scrollCouting = 0;
            this.showPrevArrow = false;
        }
        if (this.scrollCouting == this.day.recordings[this.videoIndex].alerts.length - 2) {
            this.showNextArrow = false;
        } else {
            this.showNextArrow = true;
        }
        const leftOffset = index > 0 ? (index - 1) * this.totalAlertCard : 0;
        elementHtml.scrollTo({ left: leftOffset, behavior: 'smooth' });
    }

    setEffect(recordings: any, row: number) {
        const alerts = recordings['alerts'];
        if (alerts.length > 0) {
            for (let index = 0; index < alerts.length; index++) {
                const alert = alerts[index];
                if (index == row) {
                    alert['statusClass'] = 'active';
                } else {
                    alert['statusClass'] = 'not-active';
                }
            }
        }
    }

    clearEffect(day?) {
        if (day) {
            day.recordings.forEach(recordings => {
                if (recordings.alerts.length > 0) {
                    recordings.alerts.forEach(alert => {
                        alert['statusClass'] = 'not-active';
                    });
                }
            });
        } else {
            if (this.day.recordings[this.videoIndex].alerts.length > 0) {
                this.day.recordings[this.videoIndex].alerts.forEach(alert => {
                    alert['statusClass'] = 'not-active';
                });
            }
        }
    }

    ngOnDestroy() {
        this.removeEvents();
        if (this.camSub) {
            this.camSub.unsubscribe();
        }
        if (this.videoSub) {
            this.videoSub.unsubscribe();
        }
        if (this.camerasSub) {
            this.camerasSub.unsubscribe();
        }
        if (this.getDetectionsCams) {
            this.getDetectionsCams.unsubscribe();
        }
        //this.store.dispatch(CameraActions.cleanup());
        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(DetectionActions.cleanup());
    }

    getProfile() {
        const profile = localStorage.getItem('profile_name');
        if (profile == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        } else {
            this.isPartner = false;
        }
    }

    getClients(){
        this.loadingClients = true;
        this.store.dispatch(UserActions.get_clients({
            user_sub: this.userSub
        }));
        this.clientSub = this.clients$.subscribe(data => {
            if (data) {
                this.clientList = data.clients;
                this.clientListSimple = this.clientList.map(e => {
                    return {
                        text: e.name,
                        id: e.sub
                    };
                });
                this.loadingClients = false;
            }
        });
    }

    selectClient(sub) {
        this.selectedClient = sub[0];
        this.camerasLoaded = false;
        this.initCameras();
        if (!this.isPartner && !this.isAssociate) {
            this.store.dispatch(CameraActions.get_group_cameras({
                user_sub: this.userSub,
                child_sub: this.selectedClient,
                type_request: 'cameras',
                page: this.sumGroup,
                limit: this.camLimit,
                str_filter: this.searchText
            }));
        }
        this.store.dispatch(CameraActions.get_cameras({
            user_sub: this.userSub,
            child_sub: this.selectedClient,
            page: this.sum,
            limit: this.camLimit,
            str_filter: this.searchText,
            short: true
        }));
        this.store.dispatch(CameraActions.get_invited_cameras({
            user_sub: localStorage.getItem('sub'),
            child_sub: this.selectedClient,
            str_filter: this.searchText,
            list_type: ListType.Recording
        }));
    }

    // Requisita o video com a url assinada
    getUrlSignedVideo(urlIndex: number) {
        const urlFilter = this.day.recordings.filter(function (elem, index, array) {
            if (index === urlIndex) {
                return elem;
            }
        });
        this.store.dispatch(CameraActions.get_url_signed_video({ urlSigned: urlFilter[0].url }));
    }

    getElements() {
        this.video = document.getElementById('video') as HTMLVideoElement;
        this.videoControls = document.getElementById('video-controls');
        this.playButton = document.getElementById('play');
        this.playbackIcons = document.querySelectorAll('.playback-icons use');
        this.timeElapsed = document.getElementById('time-elapsed');
        this.duration = document.getElementById('duration');
        this.progressBar = document.getElementById('progress-bar') as HTMLProgressElement;
        this.alertsOfVideo = document.getElementById('alertsOfVideo');
        // seek
        this.seek = document.getElementById('seek') as HTMLInputElement;
        this.seekToolTip = document.getElementById('seek-tooltip');
        // Controladores de volume
        this.volumeButton = document.getElementById('volume-button') as HTMLButtonElement;
        this.volumeIcons = document.querySelectorAll('.volume-button use');
        this.volumeMute = document.querySelector('use[href="#volume-mute"]');
        this.volumeLow = document.querySelector('use[href="#volume-low"]');
        this.volumeHigh = document.querySelector('use[href="#volume-high"]');
        this.volume = document.getElementById('volume') as HTMLInputElement;
        //Animação
        this.playbackAnimation = document.getElementById('playback-animation');
        //Full-screen
        this.fullScreenButton = document.getElementById('fullscreen-button') as HTMLButtonElement;
        this.videoContainer = document.getElementById('video-container');
        this.fullScreenIcons = this.fullScreenButton.querySelectorAll('use');
        this.replay = document.getElementById('replay');
        this.forward = document.getElementById('forward');
        this.time = document.getElementById('time');
    }

    hideVideo() {
        const canPlay = !!document.createElement('video').canPlayType;
        if (canPlay) {
            this.video.controls = false;
            this.videoControls.classList.remove('hidden');
        }
    }

    togglePlay() {
        if (this.video.paused || this.video.ended) {
            this.video.play();
        } else {
            this.video.pause();
        }
    }

    updatePlayButton() {
        if (this.video.paused && this.playButton && this.playbackIcons && this.playbackIcons.length > 3) {
            this.playButton.setAttribute('data-title', 'Play (k)');
            this.playbackIcons[0].classList.add('hidden');
            this.playbackIcons[1].classList.remove('hidden');
            this.playbackIcons[2].classList.remove('hidden');
            this.playbackIcons[3].classList.add('hidden');
        } else {
            this.playButton.setAttribute('data-title', 'Pause (k)');
            if (this.playbackIcons && this.playbackIcons.length > 3) {
                this.playbackIcons[0].classList.remove('hidden');
                this.playbackIcons[1].classList.add('hidden');
                this.playbackIcons[2].classList.add('hidden');
                this.playbackIcons[3].classList.remove('hidden');
            }
        }
    }

    formatTime(seconds: number): { hours: string; minutes: string; seconds: string } {
        const inDate = new Date(seconds * 1000).toISOString().substr(11, 8);
        return {
            hours: inDate.substr(0, 2),
            minutes: inDate.substr(3, 2),
            seconds: inDate.substr(6, 2)
        };
    }

    formatTimeToSeconds(time: string): number {
        let seconds = Number(time.substr(0, 2)) * 3600;
        seconds += Number(time.substr(3, 2)) * 60;
        seconds += Number(time.substr(6, 2));

        return seconds;
    }


    goToAlert(video, index) {
        const videoendedAt = this.formatTimeToSeconds(video.media_create_date.substr(11, 8));
        const alertStart = this.formatTimeToSeconds(video.alerts[index].startedat.substr(11, 8));
        const startAt = videoendedAt < alertStart ? alertStart - videoendedAt : (alertStart + 86400) - videoendedAt;
        this.video.currentTime = startAt - 10; // to seconds
    }


    setAlertsTimeInterval(video: Video) {

        if (video.alerts && video.alerts.length > 0) {

            let style = 'linear-gradient(90deg, ';
            const videoendedAt = this.formatTimeToSeconds(video.media_create_date.substr(11, 8));
            const duration = this.formatTimeToSeconds(video.media_duration);

            video.alerts.forEach(alert => {

                const alertStart = this.formatTimeToSeconds(alert.startedat.substr(11, 8));

                const startAt = videoendedAt < alertStart ? alertStart - videoendedAt : (alertStart + 86400) - videoendedAt;

                const percentOfStart = (startAt / duration) * 100;

                style += `rgba(240, 137, 111, 0) ${percentOfStart - 1.1}%, `;
                style += `rgba(240, 137, 111, 1) ${percentOfStart - 1}%, `;
                style += `rgba(240, 137, 111, 1) ${percentOfStart + 1}%, `;
                style += `rgba(240, 137, 111, 0) ${percentOfStart + 1.1}%, `;

            });
            style = style.slice(0, -2);
            style += ')';

            // this.alertsOfVideo.style.background = '-moz-linear-gradient(90deg, ' + style;
            // this.alertsOfVideo.style.background = '-webkit-linear-gradient(90deg, ' + style;
            this.alertsOfVideo.style.background = style;

        } else {
            this.alertsOfVideo.style.background = 'transparent';
        }

    }

    initializeVideo() {
        const videoDuration = Math.round(this.video.duration);
        // const hour = this.day.recordings[this.videoIndex].media_end_date.slice(11, 13);
        // const minute = this.day.recordings[this.videoIndex].media_end_date.slice(14, 16);
        this.seek.setAttribute('max', videoDuration.toString());
        this.progressBar.setAttribute('max', videoDuration.toString());
        if (videoDuration != 0) {
            this.duration.innerText = `${Math.floor(videoDuration / 60)}:${(videoDuration % 60).toLocaleString('pt-BR', {
                useGrouping: false,
                minimumIntegerDigits: 2
            })}`;
            this.time.innerText = `${Math.floor(videoDuration / 60)}:${(videoDuration % 60).toLocaleString('pt-BR', {
                useGrouping: false,
                minimumIntegerDigits: 2
            })}`;
        }
        // this.duration.setAttribute('datetime', `${hour}:${minute}`);
        this.setAlertsTimeInterval(this.day.recordings[this.videoIndex]);
    }

    updateTimeElapsed() {
        const time = this.formatTime(Math.round(this.video.currentTime));
        this.timeElapsed.innerText = `${time.minutes}:${time.seconds}`;
        this.timeElapsed.setAttribute('datetime', `${time.minutes}:${time.seconds}`);
    }

    updateProgress() {
    // const seconds = this.formatTimeToSeconds(this.day.recordings[this.videoIndex].media_create_date.substr(11, 8));
    // const time = this.formatTime(Math.round(this.video.currentTime + seconds));
    // this.timeElapsed.innerText = `${time.hours}:${time.minutes}:${time.seconds}`;
    // this.timeElapsed.setAttribute('datetime', `${time.hours}:${time.minutes}`);
        this.seek.value = Math.round(this.video.currentTime).toString();
        this.progressBar.value = Math.round(this.video.currentTime);
    }

    updateSeekTooltip(event: MouseEvent) {
        const seconds = this.formatTimeToSeconds(this.day.recordings[this.videoIndex].media_create_date.substr(11, 8));
        const target = event.target as HTMLElement;
        const skipTo = Math.round((event.offsetX / target.clientWidth) * Number(target.getAttribute('max')));
        this.seek.setAttribute('data-seek', skipTo.toString());
        const t = this.formatTime(skipTo + seconds);
        this.seekToolTip.textContent = `${t.hours}:${t.minutes}:${t.seconds}`;
        const rect = this.video.getBoundingClientRect();
        this.seekToolTip.style.left = `${event.pageX - rect.left}px`;
    }

    skip(event: Event) {
        const target = event.target as HTMLInputElement;
        const skipTo = target.dataset.seek ? target.dataset.seek : target.value;
        this.video.currentTime = Number(skipTo);
    }

    removeEvents() {
        this.playButton.removeEventListener('click', this.togglePlay.bind(this));
        this.video.removeEventListener('play', this.updatePlayButton.bind(this));
        this.video.removeEventListener('pause', this.updatePlayButton.bind(this));
        this.video.removeEventListener('click', this.togglePlay.bind(this));
        this.video.removeEventListener('click', this.animatePlayback.bind(this));
        this.video.removeEventListener('waiting', this.videoIsWaiting.bind(this));
        this.video.removeEventListener('canplay', this.canPlayVideo.bind(this));
        //Inserir duração do vídeo
        this.video.removeEventListener('loadedmetadata', this.initializeVideo.bind(this));
        this.video.removeEventListener('timeupdate', this.updateProgress.bind(this));
        //Seek
        this.seek.removeEventListener('mousemove', this.updateSeekTooltip.bind(this));
        this.seek.removeEventListener('input', this.skip.bind(this));
        //volume
        this.video.removeEventListener('volumechange', this.updateVolumeIcon.bind(this));
        //Fullscreen
        this.fullScreenButton.removeEventListener('click', this.toggleFullScreen.bind(this));
        this.videoContainer.removeEventListener('fullscreenchange', this.updateFullscreenButton.bind(this));
        this.forward.removeEventListener('click', this.forwardFn.bind(this));
        this.replay.removeEventListener('click', this.replayFn.bind(this));
    }

    updateVolume() {
        if (this.video.muted) {
            this.video.muted = false;
        }
        // this.volume.value = '10';
        this.video.volume = Number(this.volume.value);
    }

    updateVolumeIcon() {
        this.volumeIcons.forEach(icon => {
            icon.classList.add('hidden');
        });

        this.volumeButton.setAttribute('data-title', 'Mute (m)');

        if (this.video.muted || this.video.volume === 0) {
            this.volumeMute.classList.remove('hidden');
            this.volumeButton.setAttribute('data-title', 'Unmute (m)');
        }
        else if (this.video.volume > 0 && this.video.volume <= 0.7) {
            this.volumeLow.classList.remove('hidden');
        }
        else {
            this.volumeHigh.classList.remove('hidden');
        }
    }

    toggleMute() {
        this.video.muted = !this.video.muted;
        if (this.video.muted) {
            this.volume.setAttribute('data-volume', this.volume.value);
            this.volume.value = '0';
        }
        else {
            this.volume.value = this.volume.dataset.volume;
        }
    }

    animatePlayback() {
        this.playbackAnimation.animate([
            {
                opacity: 1,
                transform: 'scale(1)',
            },
            {
                opacity: 0,
                transform: 'scale(1.3)',
            }
        ], {
            duration: 500,
        });
    }

    toggleFullScreen() {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            this.videoContainer.requestFullscreen();
            document.addEventListener('fullscreenchange', () => this.controlsFullscreen(this.videoControls));
        }
    }

    controlsFullscreen(videosControls: HTMLElement) {
        if (!document.fullscreenElement) {
            videosControls.classList.remove('isFullscreen');
            document.removeEventListener('fullscreenchange', () => this.controlsFullscreen(videosControls));
        } else {
            videosControls.classList.add('isFullscreen');
        }
    }

    updateFullscreenButton() {
        this.fullScreenIcons.forEach(icon => icon.classList.toggle('hidden'));

        if (document.fullscreenElement) {
            this.fullScreenButton.setAttribute('data-title', 'Sair da tela cheia (f)');
        }
        else {
            this.fullScreenButton.setAttribute('data-title', 'Tela cheia (f)');
        }
    }

    hideControls() {
        if (this.video && this.video.paused) {
            return;
        }
        this.videoControls.classList.add('hide');
    }

    showControls() {
        if (this.videoControls) {
            this.videoControls.classList.remove('hide');
        }
    }

    keyboardShortcuts(event) {
        const { key } = event;
        switch (key) {
            case 'k':
                this.togglePlay();
                this.animatePlayback();
                if (this.video.paused) {
                    this.showControls();
                } else {
                    setTimeout(() => {
                        this.hideControls();
                    }, 2000);
                }
                break;
            case 'm':
                this.toggleMute();
                break;
            case 'f':
                this.toggleFullScreen();
                break;
        }
    }

    addEvents() {
        this.playButton.addEventListener('click', this.togglePlay.bind(this));
        this.video.addEventListener('play', this.updatePlayButton.bind(this));
        this.video.addEventListener('pause', this.updatePlayButton.bind(this));
        this.video.addEventListener('loadedmetadata', this.initializeVideo.bind(this));
        this.video.addEventListener('timeupdate', this.updateTimeElapsed.bind(this));
        this.video.addEventListener('timeupdate', this.updateProgress.bind(this));
        this.video.addEventListener('volumechange', this.updateVolumeIcon.bind(this));
        this.video.addEventListener('click', this.togglePlay.bind(this));
        this.video.addEventListener('click', this.animatePlayback.bind(this));
        this.video.addEventListener('mouseenter', this.showControls);
        this.video.addEventListener('mouseleave', this.hideControls);
        this.video.addEventListener('waiting', this.videoIsWaiting.bind(this));
        this.video.addEventListener('canplay', this.canPlayVideo.bind(this));
        this.videoControls.addEventListener('mouseenter', this.showControls);
        this.videoControls.addEventListener('mouseleave', this.hideControls);
        this.seek.addEventListener('mousemove', this.updateSeekTooltip.bind(this));
        this.seek.addEventListener('input', this.skip.bind(this));
        this.fullScreenButton.addEventListener('click', this.toggleFullScreen.bind(this));
        this.videoContainer.addEventListener('fullscreenchange', this.updateFullscreenButton.bind(this));
        this.forward.addEventListener('click', this.forwardFn.bind(this));
        this.replay.addEventListener('click', this.replayFn.bind(this));
        document.addEventListener('keyup', this.keyboardShortcuts);
    }

    changePlaySpeed(speed) {
        this.video.playbackRate = speed;
        this.speed = speed;
    }

    getVideoTitle(video: string): string {
        return video;
    }

    async playVideos(): Promise<void> {
    // this.video = document.getElementById('video') as any;
        this.video.src = this.day.recordings[this.videoIndex].url;
        this.currentVideo = this.day.recordings[this.videoIndex].url;
        this.video.load();
        this.video.playbackRate = this.speed;
        const video = await this.video.play();
        this.video.addEventListener('ended', this.playNextVideo.bind(this));
    }

    async playNextVideo() {
        this.videoIndex++;
        if (this.videoIndex < this.day.recordings.length) {
            // this.getUrlSignedVideo(this.videoIndex);
            this.video.src = this.day.recordings[this.videoIndex].url;
            this.currentVideo = this.day.recordings[this.videoIndex].url;
            this.video.load();
            this.video.playbackRate = this.speed;
            const video = await this.video.play();
        }
        else {
            this.videoIndex = 0;
        }
    }

    skipVideo(video: Video) {
        this.videoIndex = this.day.recordings.findIndex(oldVideo => video.url === oldVideo.url);
        this.video.src = this.day.recordings[this.videoIndex].url;
        this.currentVideo = this.day.recordings[this.videoIndex++].url;
        this.video.load();
        this.video.play();
        this.video.playbackRate = this.speed;
    }

    blockRetrieveVideos(bool = false){
        if (!this.currentCamera) {
            return 'Você não possui câmeras selecionadas.';
        } else if (this.dateRange.length == 0 || (this.dateRange.length > 0 && (!this.dateRange[0] || !this.dateRange[1]))) {
            return 'Selecione uma data.';
        } else if (this.endTime == '' || this.beginTime == '') {
            return 'Selecione um horário.';
        } else {
            return bool ? false : 'Buscar';
        }
    }

    retrieveVideos() {
        if (typeof this.dateRange[0] === 'number') {
            this.beginDate = formatDate(this.dateRange[0], 'dd/MM/yyyy', 'pt');
            this.endDate = formatDate(this.dateRange[1], 'dd/MM/yyyy', 'pt');
        } else {
            this.beginDate = this.dateRange[0];
            this.endDate = this.dateRange[1];
        }
        this.beginTime = this.initialTime.nativeElement.value;
        this.endTime = this.finalTime.nativeElement.value;
        if (!this.currentCamera) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'select-camera', 'cameras.select_camera');
            return;
        }
        this.loading = true;
        //Se o campo data estiver vazio, seta para a hora 00:00
        if (!this.beginTime) {
            this.beginTime = '00:00';
        }
        if (!this.endTime) {
            this.endTime = '00:00';
        }
        const creationDate = new Date(String(this.currentCamera.registration_date));
        const startDate = this.beginDate.split('/');
        const endDate = this.endDate.split('/');
        const timeBegin: string[] = this.beginTime.split(':');
        const timeEnd: string[] = this.endTime.split(':');
        const creation = creationDate.getDate() + ((creationDate.getMonth() + 1) * 30) + (creationDate.getFullYear() * 360);
        const creationDateTime = (creationDate.getDate().toString().padStart(2, '0')) + '/'
      + (creationDate.getMonth() + 1).toString().padStart(2, '0') + '/'
      + creationDate.getFullYear();
        const now = Number(endDate[0]) + Number(endDate[1] * 30) + Number(endDate[2] * 360);  // Data de hoje (Final)
        const past = Number(startDate[0]) + Number(startDate[1] * 30) + Number(startDate[2] * 360); // Outra data no passado (inicial)
        // Valida se a data inicial é maior que a final
        if (now < past) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'date-invalid', 'cameras.date_invalid');
            this.loading = false;
            return;
        }
        if (past < creation) {
            this.loading = false;
            this.validationResponse.validationResponseHandler(400, this.pageName, 'date-camera-invalid', 'cameras.date_camera_invalid', creationDateTime);
            return;
        }

        // Cria o objeto data no formato correto

        this.beginDateFormated = startDate[2] + '/' + startDate[1] + '/' + startDate[0] + ' ' + timeBegin[0] + ':' + timeBegin[1] + ':' + '00';
        this.endDateFormated = endDate[2] + '/' + endDate[1] + '/' + endDate[0] + ' ' + timeEnd[0] + ':' + timeEnd[1] + ':' + '00';

        if (now == past && Number(this.beginTime.replace(/\D/g, '')) > Number(this.endTime.replace(/\D/g, ''))) {
            this.validationResponse.validationResponseHandler(400, this.pageName, 'date-invalid', 'cameras.date_invalid');
            this.loading = false;
            return;
        }

        this.store.dispatch(CameraActions.resetting_videos({ reset: false }));
        const data = {
            user_sub: localStorage.getItem('sub'),
            child_sub: this.isPartner || this.isAssociate ? this.currentCamera.sub : null,
            camera_id: this.currentCamera.id,
            timeBegin: this.beginDateFormated,
            timeEnd: this.endDateFormated,
            withAlert: false,
            refresh: false
        };
        this.store.dispatch(CameraActions.get_videos(data));

        const subtractedMinutes = new Date(this.currentTime);
        subtractedMinutes.setMinutes(subtractedMinutes.getMinutes() - 15);
        let dateEnd = this.endDateFormated.split(' ');
        const endDatePart = dateEnd[0].split('/');
        const endHourPart = dateEnd[1].split(':');
        dateEnd = new Date(endDatePart[0], endDatePart[1] - 1, endDatePart[2], endHourPart[0], endHourPart[1], endHourPart[2]);
        this.isProcessing = dateEnd >= subtractedMinutes;
    }

    repeatRetriveVideos() {
        if (this.isProcessing) {
            if (this.countRequestRecording <= 15) {
                this.store.dispatch(CameraActions.get_videos_cleanup());
                this.countRequestRecording++;
                this.dataToProcessing = {
                    user_sub: localStorage.getItem('sub'),
                    child_sub: this.isPartner || this.isAssociate ? this.currentCamera.sub : null,
                    camera_id: this.currentCamera.id,
                    timeBegin: this.beginDateFormated,
                    timeEnd: this.endDateFormated,
                    withAlert: false,
                    refresh: (this.countRequestRecording % 3 == 0)
                };
                setTimeout(() => {
                    this.store.dispatch(CameraActions.get_videos(this.dataToProcessing));
                }, 3000);
            } else {
                this.countRequestRecording = 0;
                this.isProcessing = false;
                this.playlist[this.playlist.length - 1].recordings.pop();
                this.validationResponse.validationResponseHandler(500, this.pageName, 'not-processing-record', 'cameras.not_processing_rec');
            }
        }
    }

    chooseCam(camera: Camera) {
        const containerCameras = document.getElementById('containerCameras');

        if (this.selectedCamera != camera.id) {
            this.cameraName = camera.alias;
            this.store.dispatch(CameraActions.put_view_camera({ payload: camera }));
            this.video.classList.remove('regular-fit');
            this.video.classList.add('cover-fit');
            this.selectedVideo = false;
            this.selectedCamera = camera.id;
            this.previousCameraSelected = camera.id;
            this.previousScrollPosition = containerCameras.scrollTop;
            containerCameras.scrollTop = 0;
            if (this.isPartner || this.isAssociate) {
                //this.selectClient([camera.sub]);
                //setTimeout(() => this.reRender = true);
                //setTimeout(() => this.reRender = false);
            }
        } else {
            this.selectedCamera = -1;
            containerCameras.scrollTop = this.previousScrollPosition ? this.previousScrollPosition : 0;
            //this.store.dispatch(CameraActions.cleanup());
        }
    }

    getPreview(): string {
        if (this.currentCamera && !this.playlistLoaded && !this.isProcessing) {
            return this.currentCamera.thumbnail;
        } else {
            return `../../../assets/img/background_video.${this.isVideoProcessing ? 'svg' : 'png'}`;
        }
    }

    isView(cam: Camera): boolean {
        if (this.currentCamera) {
            return cam.id === this.currentCamera.id;
        }
        else {
            return false;
        }
    }

    resetPlaylist(): void {
        this.dateRange = [];
        this.store.dispatch(CameraActions.resetting_videos({ reset: true }));
        this.isProcessing = false;
        this.playlistLoaded = false;
        this.playlist = undefined;
        this.isVideoProcessing = false;
        this.clearDay();
        this.videoIndex = undefined;
        this.dateRange.push(this.getDay(false));
        this.dateRange.push(this.getDay(true));
        this.video.pause();
        this.video.currentTime = 0;
        this.video.src = null;
    }

    displayCamName(name: string, trunc?: number): string {
        let displayName = name;
        const testName = name.split(' ')[0];
        if (displayName.length > 15) {
            displayName = name.substring(0, 10) + '...';
        }
        //Se não há nenhum espaço em branco na string
        if (!/\s/.test(testName) && testName.length > 15) {
            const truncValue = trunc ? trunc : 12;
            displayName = `${testName.slice(0, truncValue)}...`;
        }
        return displayName;
    }

    sizePlaylist() {
        return this.playlistLoaded ? { 'height': '720px', 'width': '440px' } : {};
    }

    // Preenche as variaveis com as informações de hora resgatadas no componente
    getTime(newItem: [string, string]) {
        this.beginTime = newItem[0];
        this.endTime = newItem[1];
    }

    // Preenche as variaveis com as informações de data resgatadas do componente
    getDay(today: boolean) {
        if (today) {
            this.endDate = formatDate(this.currentTime, 'dd/MM/yyyy', 'pt');
            return this.currentTime;
        } else {
            this.beginDate = formatDate(new Date().setDate(this.currentTime.getDate()), 'dd/MM/yyyy', 'pt');
            return new Date().setDate(this.currentTime.getDate());
        }
    }

    // Preenche os componentes de data com a hora correta formatada
    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        this.dateRange[0] = dateRangeStart.value;
        this.dateRange[1] = dateRangeEnd.value;
    }

    videoIsWaiting() {
        this.videoLoaded = false;
    }

    canPlayVideo() {
        this.videoLoaded = true;
    }

    downloadVideo(url, videoIndex) {
        this.isDownloading = true;
        fetch(url, {
            method: 'GET',
        }).then(response => {
            return response.blob();
        }).then(blob => {
            const recDay = new Date(this.day.recordings[videoIndex].media_create_date).toLocaleString().replace(/\//g, '.').replace(/:/g, 'h').substring(0, 17);
            const recTime = new Date(this.day.recordings[videoIndex].media_end_date).toLocaleTimeString().replace(/:/g, 'h').substring(0, 5);
            const recName = this.cameraName + ' (' + recDay + '-' + recTime + ')';
            const blobUrl = URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', recName + '.mp4');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            this.isDownloading = false;
        });
    }

    selectDay(day = null, index = null) {
        this.scrollCouting = 0;
        this.dateSelected = true;
        this.day = day ? day : this.playlist[index];
        this.dayIndex = index;
        if (!this.isVideoProcessing) {
            this.videoIndex = 0;
            this.playVideos();
            this.clearEffect(day);
            this.getAlertsRecording(this.videoIndex);
        }
    }

    clearDay() {
        this.dateSelected = false;
        this.day = null;
        this.dayIndex = undefined;
    }

    selectVideo(index) {
        this.scrollCouting = 0;
        this.videoIndex = index;
        this.isVideoProcessing = !this.day.recordings[index].url;
        if (!this.isVideoProcessing) {
            this.clearEffect();
            this.getAlertsRecording(index);
            this.playVideos();
        } else {
            this.video.src = null;
            this.video.poster = this.getPreview();
        }
    }

    getAlertsRecording(index) {
        const recording = this.day.recordings[index];
        if (recording.alarms) {
            this.loadingAlerts = true;
            const recordingFilter = recording.media_create_date + ',' + recording.media_end_date;
            let camId = [];
            camId = [this.currentCamera.id];
            this.store.dispatch(DetectionActions.get_detections({
                userSub: localStorage.getItem('sub'),
                child_sub: this.isPartner || this.isAssociate ? this.currentCamera.sub : null,
                page: 1,
                limit: 20,
                list_cameras: camId,
                recording_filter: recordingFilter
            }));
        }
    }

    getDetectionsResult() {
        this.getDetectionsCams = this.detections$.subscribe(result => {
            if (result.model === 'detections' && this.loadingAlerts) {
                const alerts = result.detections as PaginationDetections;
                this.day.recordings[this.videoIndex].alerts = alerts.alerts;
            }
            this.loadingAlerts = false;
        });
    }

    forwardFn() {
        this.video.currentTime += 10;
    }

    replayFn() {
        this.video.currentTime -= 10;
    }

    brokenImageHandler(elementHtml) {
        elementHtml.error = false;
        elementHtml.target.src = '../../../assets/img/broken_link.svg';
    }

    getUniqueCameras(cameras: Camera[]): Camera[] {
        if (cameras) {
            // const uniqueList = [];
            const idOrdainedList = cameras.sort((a, b) => {
                return a.id - b.id;
            });

            idOrdainedList.forEach(async (current, index, list) => {
                if (current) {
                    if (!this.allCameras) {
                        this.allCameras = [current];
                        this.addThumbInCache(current);
                    } else if (this.allCameras.length > 0) {
                        const camExist = this.allCameras.find(x => x.id === current.id || x.hashname === current.hashname);
                        if (!camExist) {
                            this.allCameras.push(current);
                            this.addThumbInCache(current);
                        }
                    } else {
                        this.allCameras.push(current);
                        this.addThumbInCache(current);
                    }
                }
            });
            this.sharedCamId = this.allCameras
                .filter(allCameras => this.sharedCamsList.some(sharedCameras => allCameras.id === sharedCameras.id))
                .map(filteredCameras => filteredCameras.id);

            return this.allCameras;
        }
        return [];
    }

    async addThumbInCache(c: Camera) {
        this.IS.saveCacheThumbs(c.thumbnail);
        c.cache_thumb = of(await this.IS.getCSSBackgroundImageURL(c.thumbnail));
    }

    searchCameras() {
        if (this.camerasLoaded) {
            this.initCameras();
            clearTimeout(this.debounceSearch);
            this.debounceSearch = setTimeout(() => {
                if (!this.isPartner && !this.isAssociate) {
                    this.store.dispatch(CameraActions.get_group_cameras({
                        user_sub: this.userSub,
                        child_sub: this.selectedClient,
                        type_request: 'cameras',
                        page: this.sumGroup,
                        limit: this.camLimit,
                        str_filter: this.searchText
                    }));
                }
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    page: this.sum,
                    limit: this.camLimit,
                    str_filter: this.searchText,
                    short: true
                }));
                this.store.dispatch(CameraActions.get_invited_cameras({
                    user_sub: localStorage.getItem('sub'),
                    str_filter: this.searchText,
                    list_type: ListType.Recording
                }));
                this.camerasLoaded = false;
            }, 400);
        }
    }

    onScroll() {
        if (!this.loadingCam) {
            if (this.sumGroup <= this.allPagesGroup && (!this.isPartner && !this.isAssociate)) {
                this.loadingCam = true;
                this.sumGroup = this.actualGroup <= this.allPagesGroup ? this.sumGroup += 1 : this.sumGroup;
                this.store.dispatch(CameraActions.get_group_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    type_request: 'cameras',
                    page: this.sumGroup,
                    limit: this.camLimit,
                    str_filter: this.searchText
                }));
            }
            if (this.sum <= this.allPages) {
                this.loadingCam = true;
                this.sum = this.actual <= this.allPages ? this.sum += 1 : this.sum;
                this.store.dispatch(CameraActions.get_cameras({
                    user_sub: this.userSub,
                    child_sub: this.selectedClient,
                    page: this.sum,
                    limit: this.camLimit,
                    str_filter: this.searchText,
                    short: true
                }));
            }

            setInterval(() => {
                this.loadingCam = false;
            }, 15000);
        }
    }

    initCameras() {
        this.allCameras = [];
        this.sum = 1;
        this.sumGroup = 1;
        this.totalAmount = 0;
        this.totalAmountGroup = 0;
        this.actual = 1;
        this.actualGroup = 1;
    }

}
