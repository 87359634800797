<div class="main-content">
    <div class="first-row">
       <div class="modal-title">{{modalEdit ? 'Editar Calendário de Feriados' : 'Criar Calendário de Feriados'}}
            <span class="client-name">{{clientName}}</span>
        </div>
        <div class="close-modal">
            <i nz-icon nzType="close" (click)="closeModal()" nzTheme="outline"></i>
        </div>
    </div>
    <div class="scroll-modal">
        <div class="second-row">
            <span class="subtitle">Selecione o horário de funcionamento dos analíticos das câmeras durante os feriados.</span>
            <div class="hour-session">
                <div>
                    <nz-input-group nzCompact>
                        <input type="text" nz-input #initialTime [(ngModel)]="beginTime" class="time-begin input-date-style"
                        mask="Hh:m0" placeholder="Selecionar hora" name="beginTime" [showMaskTyped]="false"/>
                        <span style="width: 30px; height: 40px; border-left: 0px; pointer-events: none; background-color: rgb(255, 255, 255); border-right-width: 0;"
                        nz-icon nzType="swap-right" nzTheme="outline" class="input-date-style input-date-style-mid"></span>
                        <input type="text" nz-input #finalTime [(ngModel)]="endTime" class="time-end input-date-style"
                        mask="Hh:m0" placeholder="Selecionar Hora" name="endTime" [showMaskTyped]="false" (ngModelChange)="getTime(initialTime.value, finalTime.value)"/>
                        <div class="time-range-picker">
                            <app-time-range-picker (changeTime)="setTime($event)"
                                [time]="[beginTime, endTime]"
                                [calendarSelect]="true">
                            </app-time-range-picker>
                        </div>
                    </nz-input-group>
                    <span *ngIf="errorTime" class="required-error">*Campo obrigatório</span>
                </div>
                <div class="subtitle checkbox"><input [formControl]="selectWholeDay" class="hour-checkbox" type="checkbox">Alertar por 24 horas para todas as câmeras</div>
            </div>
        </div>
        <div class="third-row">
            <span class="subtitle">Os <strong>Feriados nacionais</strong> foram preenchidos automaticamente, você pode editar a listagem adicionando outros ou removendo os existentes.</span>
            <div class="holiday-buttons-section">
                <div class="holiday-button margin-button" (click)="newHolidays()">
                    <span class="holiday-button-icon" nz-icon nzType="plus" nzTheme="outline"></span>Adicionar Feriado
                </div>
                <div class="holiday-button margin-button" *ngIf="restoreHolidays" (click)="restoreNationalHolidays()">
                    <span class="holiday-button-icon" nz-icon nzType="calendar" nzTheme="outline"></span>Restaurar Feriados Nacionais
                </div>
                <div class="holiday-button" *ngIf="cleanHolidays" (click)="cleanNationalHolidays()">
                    <span class="holiday-button-icon" nz-icon nzType="delete" nzTheme="outline"></span>Limpar Feriados Nacionais
                </div>
            </div>
        </div>
        <nz-table #holidayTable class="table style-table antd-table"
                [nzFrontPagination]="false"
                [nzShowPagination]="false"
                [nzSize]="'middle'"
                [nzTableLayout]="'auto'"
                [nzData]="(allHolidaysData && allHolidaysData.length > 0) ? allHolidaysData : elementsHoliday"
                [nzLoading]="loadingHolidayList || loadingCameras"
                [nzLoadingIndicator]="loadingListTemplate">
            <thead>
                <tr>
                    <th class="table-holiday">Feriado</th>
                    <th class="table-date">Data</th>
                    <th class="table-hour"></th>
                    <th class="table-cam">Câmera</th>
                    <th class="table-delete"></th>
                </tr>
            </thead>
            <tbody [hidden]="loadingHolidayList || loadingCameras">
                <tr *ngFor="let holiday of holidayTable.data; let id = index">
                    <td class="holiday-input">
                        <input type="text" [ngModel]="holiday.alias" (ngModelChange)="getHolidayName($event, id)" placeholder="Digite aqui..."/>
                        <span *ngIf="holiday.alias == '' && !errorPass" class="required-error">*Campo obrigatório</span>
                    </td>
                    <td>
                        <div class="card_time_right">
                            <mat-form-field>
                                <mat-date-range-input [rangePicker]="picker" [class.single-date]="holiday.date_end === null" [min]="holiday.date_begin">
                                  <input [(ngModel)]="holiday.date_begin" style="font-weight:400" placeholder="Selecionar data" matStartDate #dateRangeStart>
                                  <input [(ngModel)]="holiday.date_end" style="font-weight:400" placeholder="Selecionar data" matEndDate #dateRangeEnd
                                  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <span *ngIf="(holiday.date_begin == undefined) && (holiday.start_time == '' || holiday.start_time == undefined) && !errorPass"
                              class="required-error">*Campo obrigatório
                        </span>
                    </td>
                    <td class="date-input">
                        <div class="time-range-picker">
                            <div class="time-icon" [id]="'tooltip-icon-' + id" [class.required-border]="(holiday.beginTime == '' || holiday.endTime == '' || holiday.beginTime == undefined || holiday.endTime == undefined) && !errorPass">
                                <i nz-icon nzType="clock-circle" nzTheme="outline" (click)="openTimePicker(id)"
                                    [class.time-selected]="personalizedHourButton(id)"
                                    nz-tooltip [nzTooltipTitle]="getHolidayHour(id)" nzTheme="outline"
                                    nzTooltipOverlayClassName="camera_playlist_detections"></i>
                            </div>
                            <div [hidden]="!showTime[id]" class="tooltiptext-time" [id]="'tooltip-time-' + id">
                                <ng-container>
                                    <nz-input-group nzCompact style="display: flex; align-items: center;">
                                        <input type="text" nz-input #initialTime [(ngModel)]="holiday.beginTime" class="time-begin input-date-style"
                                        mask="Hh:m0" placeholder="Selecionar hora" name="beginTime" [showMaskTyped]="false"/>

                                        <span style="width: 30px; height: 40px; border-left: 0px; pointer-events: none; background-color: rgb(255, 255, 255); border-right-width: 0;"
                                        nz-icon nzType="swap-right" nzTheme="outline" class="input-date-style input-date-style-mid"></span>

                                        <input type="text" nz-input #finalTime [(ngModel)]="holiday.endTime" class="time-end input-date-style"
                                        mask="Hh:m0" placeholder="Selecionar hora" name="endTime" [showMaskTyped]="false"/>

                                        <div class="time-range-picker">
                                            <app-time-range-picker (changeTime)="setTime($event, id)"
                                                [time]="[beginTime, endTime]"
                                                [calendarSelect]="true"
                                                [calendarTable]="true">
                                            </app-time-range-picker>
                                            <span (click)="closeTimePicker(id)" class="delete-hour" nz-icon nzType="close" nzTheme="outline"></span>
                                        </div>
                                    </nz-input-group>
                                </ng-container>
                                <span *ngIf="(holiday.beginTime == '' || holiday.endTime == '' || holiday.beginTime == undefined || holiday.endTime == undefined) && !errorPass"
                                        class="required-error">*Campo obrigatório</span>
                            </div>
                        </div>
                    </td>
                    <td class="camera-input">
                        <app-filter
                            [styles]="['multiselect-alternate-style']"
                            [list]="cameraList" 
                            type="multiselect"
                            [search]="true"
                            [placeholder]="cameraPlaceholder(holiday)"
                            [showSelected]="true"
                            [feedback]="true"
                            [selectAll]="true"
                            [hasSelectedAll]="allHolidaysData?.length > 0 ? holiday.to_all_cameras_user : true"
                            [hideActiveFill]="true"
                            [hasIconPrefix]="true"
                            [selectedList]="cameraSelect(holiday)"
                            [national]="holiday.national_holiday == true || holiday.type == 'national'"
                            iconType="video-camera"
                            [dashboard]="true"
                            [calendar]="true"
                            (filterStatusChange)="changeCams($event, id)"
                        ></app-filter>
                        <span *ngIf="(holiday.cameras_info && holiday.cameras_info.length == 0 || holiday.id_cameras && holiday.id_cameras.length == 0) && !errorPass" class="required-error">*Campo obrigatório</span>
                    </td>
                    <td>
                        <span class="delete-holiday" nz-icon nzType="close" nzTheme="outline"
                        *ngIf="(allHolidaysData && allHolidaysData.length || elementsHoliday && elementsHoliday.length) != 1"
                        (click)="removeHoliday(id)"></span>
                    </td>
                </tr>
            </tbody>
        </nz-table>
        <div class="holiday-button add-holiday" (click)="newHolidays()">
            <span class="holiday-button-icon" nz-icon nzType="plus" nzTheme="outline"></span>Adicionar Feriado
        </div>
    </div>
   <div *ngIf="modalCreate" style="display: flex; justify-content: flex-end; padding:24px;">
        <button [class.disabled]="creatingCalendar" (click)="createCalendar()" class="create-holiday-button">Criar Calendário <i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="creatingCalendar"></i></button>
    </div>
    <div style="display: flex; justify-content: flex-end; padding:24px;" *ngIf="modalEdit">
        <button (click)="closeModal()" class="create-holiday-button cancel-button">Cancelar</button>
        <button [class.disabled]="updatingCalendar" (click)="updateCalendar()" class="create-holiday-button">Salvar<i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="updatingCalendar"></i></button>
    </div>
</div>

<ng-template #loadingListTemplate>
    <div class="loadingList" style="margin-top: 60px;">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
  </ng-template>