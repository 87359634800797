import { Component, OnInit, OnDestroy, Inject, EventEmitter, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AppState } from 'app/store/model';
import { UserActions } from '../Services/actions';
import { Observable, Subscription, filter, skip } from 'rxjs';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { ResultAlert } from 'app/Shared/models';
import { Holidays } from '../models';

@Component({
    selector: 'app-user-calendar-config',
    templateUrl: './user-calendar-config.component.html',
    styleUrl: './user-calendar-config.component.scss'
})
export class UserCalendarConfigComponent implements OnInit, OnDestroy {
    @Output() modalClose = new EventEmitter<string>();
    readonly deleteHolidayResult$ = this.store.select((state: AppState) => state.Users.deleteHoliday);
    readonly updateCalendar$ = this.store.select((state: AppState) => state.Users.holidays);

    deletedHolidaySub: Subscription;
    swalDecisionSub: Subscription;
    updateCalendarSub: Subscription;
    calendarSub: Subscription;

    clientName: string;
    cameras: any;
    calendarListData: Holidays[];
    loadingCalendarList: boolean;
    user_sub: string;
    child_sub: string;
    expandedCameraList: boolean;
    pageName = 'calendar-list';
    changedCalendar: Holidays;
    changedCalendarIndex: number;
    disableLoading: boolean[] = new Array(50).map(x => false);
    deleteLoading: boolean[] = new Array(50).map(x => false);
    deleteDisabled: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: SharedService, private validationResponse: ValidationResponseHandlerModule, private readonly store: Store<AppState>, ){
        // this.calendarList$ = this.store.select((state: AppState) => state.Users.calendarList);
    }

    ngOnInit(): void {
        this.loadingCalendarList = true;
        this.clientName = this.data.data.client_name;
        this.cameras = this.data.data.cameras;
        this.user_sub = localStorage.getItem('sub');
        this.child_sub = localStorage.getItem('clientView');
        this.expandedCameraList = false;
        this.deleteDisabled = false;
        this.getCalendarList();
        this.getCalendarListResult();
        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    closeModal() {
        this.modalClose.emit();
        this.store.dispatch(UserActions.update_calendar_clear());
    }

    ngOnDestroy() {
        if (this.calendarSub) {
            this.calendarSub.unsubscribe();
        }
        if (this.updateCalendarSub) {
            this.updateCalendarSub.unsubscribe();
        }
        if (this.deletedHolidaySub) {
            this.deletedHolidaySub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }

        this.store.dispatch(UserActions.update_calendar_clear());
        this.store.dispatch(UserActions.get_calendar_list_clear());
        this.store.dispatch(UserActions.delete_calendar_clear());
        this.closeModal();
        this.loadingCalendarList = false;
    }

    openCreateCalendar(type: boolean) {
        if (type == false) {
            localStorage.setItem('tipoModal', 'edit');
        } else {
            localStorage.setItem('tipoModal', 'create');
        }
        const dataModal = {
            data : {
                client_name: this.clientName,
                cameras: this.cameras
            },
            modalHeight: '90vh',
            modalWidth: '80vw',
            backdropClass: 'lightBackdrop',
            tipoModal: 'criar_calendario'
        };
        this.service.returnDataToModal(dataModal);
    }

    getCalendarList() {
        this.store.dispatch(UserActions.get_calendar_list({
            user_sub: this.user_sub,
            child_sub: this.child_sub,
        }));
    }

    getCalendarListResult() {
        this.calendarSub =  this.store.select((state: AppState) => state.Users.calendarList).pipe(filter((value) => !!value)).subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.loadingCalendarList = false;
                    this.calendarListData = result.data;
                    this.deleteDisabled = false;
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-result-fail', 'user.calendar-result-fail');
                    this.store.dispatch(UserActions.get_calendar_list_clear());
                    this.loadingCalendarList = false;
                    this.deleteDisabled = false;
                }
            }
        });
    }

    formatDateRange(start_time: string, end_time: string) {
        const start = new Date(start_time);
        const end = new Date(end_time);
        if (start.toLocaleDateString() === end.toLocaleDateString()) {
            return start.toLocaleDateString();
        }
        return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
    }

    formatHourRange(start_time: string, end_time: string) {
        const start = new Date(start_time);
        const end = new Date(end_time);
        if (start.getHours() === 0 && start.getMinutes() === 0 && end.getHours() === 23 && end.getMinutes() === 59) {
            return '24 horas';
        }
        return start.toLocaleTimeString() + ' - ' + end.toLocaleTimeString();
    }

    onChangeSwitchCalendar(event: boolean, holidayHash: string) {
        console.log('event', event);
    }

    toggleCameraList() {
        this.expandedCameraList = !this.expandedCameraList;
    }

    disableHoliday(calendar, index) {
        this.disableLoading[index] = true;
        calendar.active = !calendar.active;
        this.changedCalendar = calendar;
        this.changedCalendarIndex = index;
        if (index >= 0) {
            if (calendar.active) {
                this.validationResponse.validationResponseHandler(200, this.pageName, 'update-active', 'cameras.update_active');
            } else {
                this.validationResponse.validationResponseHandler(200, this.pageName, 'update-inactive', 'cameras.update_inactive');
            }
        }
    }

    disableHolidayResult() {
        if (this.updateCalendarSub) {
            this.updateCalendarSub.unsubscribe();
        }
        this.updateCalendarSub =  this.updateCalendar$.subscribe(result => {
            if (result) {
                this.disableLoading[this.changedCalendarIndex] = false;
                if (result.status === 2) {
                    const status = this.calendarListData[this.changedCalendarIndex].active;
                    if (status == false) {
                        this.validationResponse.validationResponseHandler(200, 'user', 'calendar-holiday-disable-success', 'user.calendar-holiday-disable-success');
                        this.calendarListData[this.changedCalendarIndex].active = false;
                    } else {
                        this.validationResponse.validationResponseHandler(200, 'user', 'calendar-holiday-able-success', 'user.calendar-holiday-able-success');
                        this.calendarListData[this.changedCalendarIndex].active = true;
                    }
                    this.store.dispatch(UserActions.update_calendar_clear());
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-holiday-disable-fail', 'user.calendar-holiday-disable-fail');
                    this.calendarListData[this.changedCalendarIndex].active = !this.changedCalendar.active;
                    this.store.dispatch(UserActions.update_calendar_clear());
                }
            }
        });
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'updateActive' || returnResultSwal.methodCalled === 'updateInactive') {
            if (returnResultSwal.isConfirmed) {
                const transformedHolidays: Holidays[]  = this.transformHolidays(this.calendarListData);
                this.store.dispatch(UserActions.update_calendar({
                    user_sub: this.user_sub,
                    child_sub: this.child_sub,
                    holidays: transformedHolidays
                }));
                this.disableHolidayResult();
            } else if (returnResultSwal.isDismissed) {
                this.calendarListData[this.changedCalendarIndex].active = !this.changedCalendar.active;
            }
        } else if (returnResultSwal.methodCalled === 'deleteCalendar') {
            if (returnResultSwal.isConfirmed) {
                this.deleteLoading[this.changedCalendarIndex] = true;
                this.store.dispatch(UserActions.delete_calendar({
                    user_sub: this.user_sub,
                    child_sub: this.child_sub,
                    hash_holiday: this.changedCalendar.hash_holiday
                }));
                this.deleteHolidayResult();
            }
            else if (returnResultSwal.isDismissed) {
                this.deleteLoading[this.changedCalendarIndex] = false;
                this.deleteDisabled = false;
            }
        }
    }

    deleteHoliday(calendar, index) {
        this.changedCalendar = calendar;
        this.changedCalendarIndex = index;
        this.deleteDisabled = true;
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete', 'calendar.confirm_delete');
    }

    deleteHolidayResult() {
        if (this.deletedHolidaySub) {
            this.deletedHolidaySub.unsubscribe();
        }
        this.deletedHolidaySub =  this.deleteHolidayResult$.subscribe(result => {
            if (result) {
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'calendar-holiday-delete-success', 'user.calendar-holiday-delete-success');
                    this.getCalendarList();
                }
                else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'calendar-holiday-delete-fail', 'user.calendar-holiday-delete-fail');
                    this.deleteDisabled = false;
                }
                this.deleteLoading[this.changedCalendarIndex] = false;
                this.store.dispatch(UserActions.get_calendar_list_clear());
                this.store.dispatch(UserActions.delete_calendar_clear());
            }
        });
    }

    transformHolidays(holidays) {
        return holidays.map(holiday => {
            const holidayCams = holiday.cameras_info.map(cams => ({ id: cams.id, hash_holiday: holiday.hash_holiday }));
            return {
                alias: holiday.alias,
                start_time: holiday.start_time,
                end_time: holiday.end_time,
                national_holiday: holiday.national_holiday,
                active: holiday.active,
                all_day: holiday.all_day,
                to_all_cameras_user: holiday.to_all_cameras_user,
                cameras_info: holidayCams
            };
        });
    }

}
