<div class="main-content background-container">
    <div class="button-close">
        <i class="material-icons hover-close" (click)="closeModal()">close</i>
    </div>
    <span class="style-title">Configurações do usuário</span>

    <div class="information">
        <div class="row">
            <div class="col-md-4 col-sm-4 col-4">
                <span class="label-title">Nome:</span>
                <div class="input-data">
                   <span>{{guestName}} <span *ngIf="!guestName || null">Nada consta.</span> </span>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-4">
                <span class="label-title">Email:</span>
                <div class="input-data">
                    <span>{{guestEmail}} <span *ngIf="!guestEmail || null">Nada consta.</span></span>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-4">
                <span class="label-title">Telefone:</span>
                <div class="input-data">
                    <span>{{guestPhone}} <span *ngIf="!guestPhone || null">Nada consta.</span></span>
                </div>
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-md-4 col-sm-4 col-4" *ngIf="(isClientGuest || (isAssociate && !adminAssociate))">
                <span class="label-title">Tipo de usuário</span>
                <div class="input-data">
                    <span>{{guestPermissionsAlias}} <span *ngIf="!guestPermissionsAlias || null">Nada consta.</span></span>
                </div>
            </div> -->
            <div #info class="col-md-4 col-sm-4 col-4" *ngIf="isPartner || adminAssociate">
                <span class="label-title">Tipo de usuário
                    <i
                        class="tooltip_icon"
                        nz-icon nzType="info-circle"
                        nzTheme="outline"
                        nz-popover
                        [nzPopoverContent]="tooltip"
                        nzPopoverTrigger="hover"
                        nzPopoverPlacement="topLeft"
                        [nzPopoverOrigin]="info"
                        nzPopoverMouseLeaveDelay="0.5"
                        nzPopoverOverlayClassName="tooltip-partner"
                    ></i>
                </span>
                <nz-select [(ngModel)]="selectedValue" nzBorderless nzPlaceHolder="Selecionar..." class="label-container">
                    <nz-option nzLabel="Comum" nzValue="Comum"></nz-option>
                    <nz-option nzLabel="Administrador" nzValue="Administrador"></nz-option>
                </nz-select>
            </div>
            <div class="col-md-4 col-sm-4 col-4">
                <span class="label-title">Cadastrado em:</span>
                <div class="input-data">
                    <span>{{guestDate}} <span *ngIf="!guestDate || null">Nada consta.</span></span>
                </div>
            </div>
            <div class="col-md-4 col-sm-4 col-4">
                <span class="label-title">Último acesso em:</span>
                <div class="input-data">
                    <span>{{guestLastAccess}} <span *ngIf="!guestLastAccess || null">Nada consta.</span></span><br/>
                    <span class="type-connection">Via {{guestDevice}} <span *ngIf="!guestDevice || null">Nada consta.</span></span>
                </div>
            </div>
        </div>

        <div class="row buttons" *ngIf="isPartner || adminAssociate">
            <div class="left-buttons">
                <button type="button" class="btn btn-ant btn-secondary button_delete_guest"
                (click)="deleteAssociated()"><i nz-icon nzType="delete" nzTheme="outline" class="icon-delete"></i><span>Excluir usuário</span>
                <i nz-icon nzType="loading" nzTheme="outline" *ngIf="loading" class="posfix"></i>
                </button>
            </div>
            <div *ngIf="(isPartner || adminAssociate)" class="right-buttons">
                <button class="btn btn-ant btn-secondary cancel-button" (click)="closeModal()">
                    Cancelar
                </button>
                <button class="btn btn-ant btn-primary submit-button" (click)="saveChanges()" [disabled]="save_changes">
                    Salvar Alterações
                    <span nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loadingUpdate"></span>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #tooltip>
    <div class="flex flex-col tooltip-partner">
        <table>
          <tr>
              <td class="tooltip-title">Permissões do usuário</td>
              <th class="no-border" scope="col">Comum</th>
              <th class="no-border" scope="col">Admin</th>
          </tr>
          <tr>
              <th scope="row">Administração dos dados da conta e dos meus usuários</th>
              <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
              <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
          </tr>
          <tr>
            <th scope="row">Administração dos clientes</th>
            <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
            <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
          </tr>
          <tr>
            <th scope="row">Administração de câmeras, analíticos e convidados</th>
            <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
            <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
          </tr>
          <tr>
            <th scope="row">Administração de estoque</th>
            <td><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
            <td><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i></td>
          </tr>
          <tr>
            <th class="no-border" scope="row">Administração de grupos de câmeras</th>
            <td class="no-border"><i nz-icon nzType="close" class="icon-col" nzTheme="outline"></i></td>
            <td class="no-border"><i nz-icon nzType="check" class="icon-col" nzTheme="outline"></i>
            </td>
          </tr>
      </table>
    </div>
</ng-template>
