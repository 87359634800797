<!-- Primeira Linha - Nome, data -->
<div class="main-content">
    <div class="row">
        <!-- Nome da câmera -->
        <div class="col-md-6 col-sm-6 col-6">
            <form class="example-form" [formGroup]="cameraForm">
                <div class="example-full-width input_nome_position">
                    <div class="label-name">
                        <span>Nome da câmera:</span>
                    </div>
                    <input nz-input type="text" placeholder="Digite aqui..." formControlName="nome_camera" class="input-container"
                    [readonly]="!(!is_guest && (!isAssociate || adminAssociate || adminClientAssociate))" />
                    <mat-error *ngIf="cameraForm.get('nome_camera').hasError('required')">
                        O nome da câmera é <strong>obrigatório</strong>
                    </mat-error>
                </div>
            </form>
        </div>
        <!-- data do cadastro -->
        <div class="col-md-3 col-sm-3 col-3">
            <div class="example-full-width input_nome_position permission_for_select">
                <div class="label-name">
                    <span>Cadastrada em:</span>
                </div>
                <div class="input-data-convert">
                    <span>{{registerAtConvert}}</span>
                </div>
            </div>
        </div>
        <!-- data do ativação -->
        <div class="col-md-3 col-sm-3 col-3">
            <div class="example-full-width input_nome_position permission_for_select">
                <div class="label-name">
                    <span>Ativada em: <i nz-icon nzType="question-circle" style="vertical-align: 0.1em !important;" nz-tooltip
                        nzTooltipTitle="A câmera é ativada após 2 horas interruptas de conexão" nzTooltipOverlayClassName="camera-actived-date"></i>
                    </span>
                </div>
                <div class="input-data-convert">
                    <span>{{camera.status && activedAtTwoHours ? activedAtConvert : '--/--/-- --:--:--'}}</span>
                </div>
            </div>
        </div>
    </div>
    <!-- Segunda Linha, RTSP -->
    <div class="row">
        <div class="col-md-10 col-sm-10 col-10">
            <!-- Link RTSP -->
            <form class="example-form">
                <div class="example-full-width input_rtsp_position" [formGroup]="cameraForm">
                    <div class="label-name">
                        <span *ngIf="isRTMP">Link RTMP:</span>
                        <span *ngIf="!isRTMP">Link RTSP:</span>
                    </div>
                    <div class="flex items-center">
                        <input [hidden]="!isRTMP" nz-input type="text" [readonly]="isRTMP" placeholder="Digite aqui..." formControlName="link_rtmp" class="input-container"/>
                        <input [hidden]="isRTMP" nz-input type="text" [readonly]="isRTMP" placeholder="Digite aqui..." formControlName="link_rtsp" class="input-container"/>
                        <i nz-icon nzType="question-circle" class="info-icon" style="vertical-align: 0.1em !important;" nz-tooltip
                                   nzTooltipTitle="Preencha os campos com o tipo de endereço RTSP para DVR/NVR: Usuário: Usuário do seu dispositivo. Senha: Senha do seu usuário.
                                   Ip: Domínio ou número que identifica um dispositivo de rede,sendo composto por quatro números entre 0 e 255 separados por pontos, por exemplo: 192.168.66.254.
                                   Porta: Número entre 1 a 65535 serve para completar o endereço de destino de sessão de comunicação com o dispositivo de rede.
                                   000: Número do canal (câmera) que deseja transmitir,sendo composto por três dígitos. Essas informações são fornecidas nas especificações do dispositivo
                                   e seu fabricante. Link de comunicação com a câmera. Preencha os campos abaixo que compõe o formato do link HTTP" nzTooltipOverlayClassName="camera-link-rtsp"></i>
                                   <label id="radio-camera-online" nz-radio style="white-space: nowrap" [ngClass]="{ 'ant-radio-checked': cameraForm.get('isCameraOnline').value}" (click)="changeCameraOnline()">Câmera Online</label>
                    </div>
                    <mat-error *ngIf="cameraForm.get('link_rtsp').hasError('required') && cameraForm.get('link_rtsp').touched">
                        O link RTSP é <strong>obrigatório</strong>
                    </mat-error>
                    <mat-error *ngIf="cameraForm.get('link_rtsp').hasError('pattern') && cameraForm.get('link_rtsp').touched">
                        Formato de link inválido
                    </mat-error>
                    <mat-error *ngIf="cameraForm.get('link_rtmp').hasError('required') && cameraForm.get('link_rtmp').touched">
                        O link RTMP é <strong>obrigatório</strong>
                    </mat-error>
                </div>
            </form>
        </div>
    </div>
    <!-- Linha informativa -->
    <div class="row">
        <div *ngIf="!isRTMP" class="col-md-11 col-sm-11 col-11 inner_margin">
            <div class="label-help">
                <span>Formato Sugerido rtsp://admin:meulogin&#64;meuddns.com.br/Streaming/Channels/000</span>
            </div>
        </div>
    </div>
    <div *ngIf="camera.integrated_camera != null">
        <!-- Link HTTP -->
        <div class="row">
            <div class="col-md-10 col-sm-10 col-10">
                <form class="example-form">
                    <div class="example-full-width input_rtsp_position" [formGroup]="intregatedCamForm">
                        <div class="label-name">
                            <span>Link HTTP:</span>
                        </div>
                        <div class="input-info-icon" [ngStyle]="{'width': camera.integrated_camera.status_camera_integrated == 'running' ? '533px' : ''}">
                            <input nz-input type="text" placeholder="Digite aqui..." formControlName="url_http" class="input-container"/>
                            <i nz-icon nzType="question-circle" class="info-icon" style="vertical-align: 0.1em !important;" nz-tooltip
                               nzTooltipTitle="Link de comunicação com a câmera. Preencha os campos abaixo que compõe o formato do link HTTP" nzTooltipOverlayClassName="camera-actived-date"></i>
                            <div *ngIf="camera.integrated_camera.status_camera_integrated == 'warning' && !processingTag" style="width: 400px;">
                                <span class="integrated-tag pending" nz-tooltip nzTooltipOverlayClassName="camera-actived-date"
                                      nzTooltipTitle="Execução pendente, tente novamente no botão ao lado">
                                    <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="margin-right: 8px;"></i>Execução pendente
                                </span>
                                <span (click)="retry()" [hidden]="intregatedCamForm.get('password').value !== camera.integrated_camera.password ||
                                                intregatedCamForm.get('user').value !== camera.integrated_camera.login ||
                                                intregatedCamForm.get('url_http').value !== camera.integrated_camera.url_http ||
                                                intregatedCamForm.get('model_hash').value !== camera.integrated_camera.camera_model_hash"
                                      nz-tooltip nzTooltipOverlayClassName="cam-name" nzTooltipTitle="Tentar novamente" class="reload-thumb">
                                    <i nz-icon nzType="reload" nzTheme="outline"></i>
                                </span>
                            </div>
                            <span *ngIf="processingTag || camera.integrated_camera.status_camera_integrated == 'processing'" class="processing">
                                       <i nz-icon nzType="loading" nzTheme="outline" style="margin-right: 8px; font-size: 20px;"></i>Processando
                            </span>
                            <span *ngIf="camera.integrated_camera.status_camera_integrated == 'disabled'" nz-tooltip nzTooltipOverlayClassName="camera-actived-date"
                                  nzTooltipTitle="Edite as configurações incoerentes da câmera e salve as alterações" class="integrated-tag">
                                <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="margin-right: 8px;"></i>Sem comunicação
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Formulário de câmera integrada -->
        <div class="flex container_information" [formGroup]="intregatedCamForm">
            <!-- Modelo da câmera -->
            <div class="col-6 cam-model">
                <form class="example-form">
                    <div class="example-full-width input_nome_position cam-model">
                        <div class="label-name">
                            <span>Modelo da câmera:</span>
                        </div>
                        <app-filter *ngIf="modelList?.length > 0"
                                    [styles]="['simple-alternate-style']"
                                    [list]="modelList"
                                    type="simple"
                                    [placeholder]="camera.integrated_camera.camera_model_name"
                                    [simpleMulti]="true"
                                    [hideActiveFill]="true"
                                    [showSelected]="true"
                                    [feedback]="true"
                                    [modelCam]="true"
                                    [modelCamData]="true"
                                    (filterStatusChange)="getModel($event)">
                        </app-filter>
                        <app-filter *ngIf="!modelList || modelList.length == 0"
                                    [styles]="['simple-alternate-style']"
                                    [list]="modelList"
                                    [blocked]="!modelList"
                                    type="simple"
                                    [modelCam]="true"
                                    [placeholder]="camera.integrated_camera.camera_model_name">
                        </app-filter>
                    </div>
                </form>
            </div>
            <!-- Usuário -->
            <div class="example-full-width input_user_position permission_for_select cam-model">
                <div class="label-name">
                    <span>Usuário:</span>
                </div>
                <input nz-input type="text" placeholder="Digite aqui..." formControlName="user" class="input-container" onpaste="return false;"/>
            </div>
            <!-- Senha -->
            <div class="example-full-width input_user_position permission_for_select">
                <div class="label-name">
                    <span>Senha:</span>
                </div>
                <div class="input-field">
                    <input [type]="hide ? 'password' : 'text'" placeholder="Digite sua senha" formControlName="password" class="input-container" onpaste="return false;">
                    <i nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" nzTheme="outline" (click)="hide = !hide" class="clickable input-icon"></i>
                </div>
            </div>
        </div>
    </div>
    <!-- Botões -->
    <div class="row" *ngIf="!is_guest && (!isAssociate || adminAssociate || adminClientAssociate)">
        <div class="col-md-7 col-sm-7 col-7">
            <button type="button" class="btn btn-md btn-Danger tamButton button_cancel_permission"
                (click)="deleteCam(camera)"><i nz-icon nzType="delete" class="icon-delete" nzTheme="outline"></i><span>Excluir Câmera</span></button>
        </div>
        <div class="col-md-5 col-sm-5 col-5 margin_buttons">
            <button type="button" class="btn btn-md tamButton button_cancel_camera"
                (click)="closeModal()"><span>Cancelar</span></button>
            <button type="button" class="btn btn-md btn-primary tamButton button_save_permission"
                [disabled]="!save_changes || loading ||
                ((cameraForm.get('link_rtsp').hasError('pattern') || cameraForm.get('link_rtsp').hasError('required')) 
                && cameraForm.get('link_rtsp').touched)" (click)="saveChanges()">
                <span>Salvar Alterações</span> <i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loading"></i> </button>
        </div>
    </div>
</div>
