<div class="gradient">
  <div class="card-forgot">
    <div class="icon-return">
      <img (click)="goToLogin()" src="../../../assets/img/arrow_left.svg" alt="arrow-left">
    </div>
    <form [formGroup]="forgotForm">
      <div class="card-style-header">
        <span *ngIf="!toPassword" class="title_reset_password">
          Esqueceu a senha?
        </span>
        <span *ngIf="toPassword" class="title_reset_password">
          Redefina sua senha
        </span>
      </div>
      <div class="card-body">
        <div id="pressEnter">
          <div *ngIf="currentStep === 0">
            <div class="card-description">
              <span class="title-style">Informe seu e-mail cadastrado para receber o código de verificação e redefinir sua senha.</span>
            </div>
            <div class="row">
              <div class="block-email">
                <div class="form-group has-default">
                  <div class="label-email">
                    <span>E-mail cadastrado</span>
                  </div>
                  <nz-input-group [nzPrefix]="prefixTemplateUser" class="input-container">
                    <input type="email" class="input-value" formControlName="email" nz-input placeholder="Digite seu e-mail" />
                  </nz-input-group>
                  <ng-template #prefixTemplateUser><i nz-icon nzType="user" nzTheme="outline"></i></ng-template>
                  <mat-error
                    *ngIf="forgotForm.get('email').hasError('email') && !forgotForm.get('email').hasError('required')">
                    Por favor digite um formato de e-mail válido
                  </mat-error>
                  <mat-error *ngIf="forgotForm.get('email').hasError('required') && forgotForm.get('email').touched">
                    Email <strong>é requerido</strong>
                  </mat-error>
                </div>
                <div class="text-center margin-spinner">
                  <button *ngIf="!loading" type="button" (click)="forgotPassword()"
                    class="btn btn-primary btn-md button-style">Enviar código</button>
                  <div *ngIf="loading" class="spin col-12 center-spinner">
                    <span nz-icon nzType="loading" nzTheme="outline" style="font-size:40px"></span>
                  </div>
                </div>
                <div style="padding-top: 5rem;">
                  <div class="footer-wesafer">
                    <span>Powered by WeSafer</span>
                  </div>
                  <div class="logo-footer">
                    <img src="../../../assets/img/logo_login.png" alt="" srcset="" *ngIf="isNextVision">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="currentStep === 1">
            <div class="row">
              <span class="title-style">Digite o código de verificação que enviamos para o e-mail <span style="font-weight: bold;">{{email}}</span></span>
            </div>
            <div class="row">
              <div class="center-image">
                <img src="../../../assets/img/email_icon.svg" alt="email_icon">
              </div>
              <code-input #codeInput [codeLength]="6" (codeCompleted)="onCodeCompleted($event)" (codeChanged)="onCodeChanged($event)">
              </code-input>
            </div>
            <div class="error-code" *ngIf="codeError">
              <span>*Código digitado inválido, preencha<br>novamente o código recebido.</span>
            </div>
            <div class="row" *ngIf="!resetEmail">
              <span class="title-style email-seconds">Você pode reenviar o código<br>em <span id="timer"></span> segundos</span>
            </div>
            <div class="row" *ngIf="resetEmail">
              <span class="title-style email-seconds renv-email" [ngClass]="loading || envitedEmail ? 'disabled' : ''" (click)="forgotPassword()">Reenviar código</span>
              <span class="title-style email-seconds renv-email" *ngIf="loading">
                <i nz-icon nzType="loading" nzTheme="outline"></i>
              </span>
              <span *ngIf="!loading && envitedEmail" class="title-style email-seconds renv-email">
                <i nz-icon nzType="check-circle" nzTheme="outline" style="margin-right: 0.5rem;"></i> <span>Código reenviado</span>
              </span>
            </div>
            <div class="row">
              <div class="button-next">
                <button type="button" (click)="goToPassword()" class="btn btn-primary btn-md button-style">Redefinir Senha</button>
              </div>
            </div>
            <div class="row">
              <span class="title-style email-seconds">
                Não recebeu o código? Verifique o spam<br>ou
                <span class="small-text" (click)="reSendCode()">tente outro endereço de e-mail</span>.
              </span>
            </div>
            <div class="footer-wesafer">
              <span>Powered by WeSafer</span>
            </div>
            <div class="logo-footer">
              <img src="../../../assets/img/logo_login.png" alt="" srcset="" *ngIf="isNextVision">
            </div>
          </div>
          <div *ngIf="currentStep === 2">
            <div class="row">
              <span class="title-style" style="margin-bottom: 20px;">
                  Sua senha deve ser composta por pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e um caractere
                  <br>especial (ex: !&#64;#$%&*).</span>
            </div>
            <div class="row">
              <div class="block-password">
                <div class="form-group has-default">
                  <div class="label-email">
                    <span>Nova senha</span>
                  </div>
                  <div class="input-group">
                    <nz-input-group [nzSuffix]="suffixTemplate" class="input-container">
                      <input class="input-value" [type]="hide ? 'text' : 'password'" nz-input placeholder="Digite sua senha" formControlName="password" />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                      <i class="input-eye" nz-icon [nzType]="hide ? 'eye' : 'eye-invisible'" (click)="hide = !hide"></i>
                    </ng-template>
                  </div>
                  <mat-error *ngIf="forgotForm.get('password').hasError('required') && forgotForm.get('password').touched">
                    Senha <strong>é requerido</strong>
                  </mat-error>
                  <div class="min-codes">
                    <span>Mínimo de 8 caracteres</span>
                  </div>
                </div>
                <div class="form-group has-default">
                  <div class="label-email">
                    <span>Confirmar senha</span>
                  </div>
                  <div class="input-group">
                    <nz-input-group [nzSuffix]="suffixTemplate" class="input-container">
                      <input class="input-value" [type]="hide ? 'text' : 'password'" nz-input placeholder="Digite sua senha" formControlName="confirm" onpaste="return false;"/>
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                      <i class="input-eye" nz-icon [nzType]="hide ? 'eye' : 'eye-invisible'" (click)="hide = !hide"></i>
                    </ng-template>
                  </div>
                  <div class="error-code" *ngIf="forgotForm.get('password').invalid && (forgotForm.get('password').dirty || forgotForm.get('password').touched)">
                    <span>*Sua senha deve conter ao menos
                      <span *ngIf="forgotForm.get('password').errors.minlength">8 caracteres, </span>
                      <span *ngIf="forgotForm.get('password').errors.lowerCaseError;">1 letra minúscula, </span>
                      <span *ngIf="forgotForm.get('password').errors.upperCaseError;">1 letra maiúscula, </span>
                      <span *ngIf="forgotForm.get('password').errors.numberError;">1 número, </span>
                      <span *ngIf="forgotForm.get('password').errors.symbolError;">1 caractere especial (ex: !&#64;#$%&*)</span>.
                    </span>
                  </div>
                  <mat-error *ngIf="forgotForm.get('confirm').hasError('required') && forgotForm.get('confirm').touched">
                    Confirmar senha <strong>é requerido<br></strong>
                  </mat-error>
                  <mat-error *ngIf="confirmation">
                    Senhas <strong>não conferem!</strong>
                  </mat-error>
                </div>
                <div class="text-center margin-spinner-two">
                  <button *ngIf="!loading && !msgSuccess" type="button" id="buttonConfirmNewPassword"
                    (click)="confirmNewPassword()"
                    class="btn btn-primary btn-md button-style">Redefinir senha
                  </button>
                  <button *ngIf="loading" disabled class="btn btn-primary btn-md button-loading">
                    <span>Redefinir senha</span>
                    <i nz-icon nzType="loading" nzTheme="outline"></i>
                  </button>
                  <button *ngIf="msgSuccess" disabled class="btn btn-primary btn-md button-loading">
                    <span>Senha redefinida</span>
                    <i nz-icon nzType="check-circle" nzTheme="outline"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
