import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Camera, PaginationCameras } from 'app/cameras/models';
import { CameraActions } from 'app/cameras/Services/actions';
import { isValidURL, validateCPFCNPJ, confirmPassword, validatePassword, numberValidator } from 'app/Login/models';
import { ResultAlert } from 'app/Shared/models';
import { SharedService } from 'app/Shared/Services/shared.service';
import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { AppState } from 'app/store/model';
import { Subscription } from 'rxjs';
import { CognitoAttributes, UpdateClient, UserInventory } from '../models';
import { UserActions } from '../Services/actions';
import { UserRtmpWarningComponent } from '../user-rtmp-warning/user-rtmp-warning.component';

@Component({
    selector: 'app-client-info',
    templateUrl: './user-client-info.component.html',
    styleUrls: ['./user-client-info.component.scss']
})
export class UserClientInfoComponent implements OnInit, OnDestroy {
    readonly userData$ = this.store.select((state: AppState) => state.Users.userData);
    readonly resendWelcomeEmail$ = this.store.select((state: AppState) => state.Users.resendEmail);
    readonly blockClient$ = this.store.select((state: AppState) => state.Users.blockClient);
    readonly editClient$ = this.store.select((state: AppState) => state.Users.updateClientResult);
    readonly editPartner$ = this.store.select((state: AppState) => state.Users.updatePartnerResult);
    readonly upResult$ = this.store.select((state: AppState) => state.Users.updateResult);
    readonly cameras$ = this.store.select((state: AppState) => state.camera.cameras);
    readonly userInventory$ = this.store.select((state: AppState) => state.Users.userInventory);
    readonly deleteResult$ = this.store.select((state: AppState) => state.Users.deleteResult);
    readonly deletePartner$ = this.store.select((state: AppState) => state.Users.deletePartner);

    resend_welcome_email_sub: Subscription;
    user_data_sub: Subscription;
    block_client_sub: Subscription;
    swalDecisionSub: Subscription;
    edit_client: Subscription;
    edit_partner: Subscription;
    updateSub: Subscription;
    getCams: Subscription;
    userInventory: Subscription;
    delete_client_sub: Subscription;
    delete_partner_sub: Subscription;

    public clientForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        phone: new UntypedFormControl('', [Validators.required, numberValidator]),
        cpforcnpj: new UntypedFormControl('', [Validators.required, validateCPFCNPJ]),
        password: new UntypedFormControl('', [Validators.minLength(8), validatePassword]),
        confirm: new UntypedFormControl('', []),
        costCenter: new UntypedFormControl('', [Validators.required]),
        costCenterBond: new UntypedFormControl('', [Validators.required]),
        panicButton: new UntypedFormControl('', []),
        monitoringButton: new UntypedFormControl('', []),
        panicName: new UntypedFormControl('', [Validators.required]),
        panicPhone: new UntypedFormControl('', [Validators.required, numberValidator]),
        contractLink: new UntypedFormControl('', [isValidURL]),
        permissionPartner: new UntypedFormControl(false, []),
    }, {validators: confirmPassword});

    public cpfcnpjMask = function (rawValue) {
        const strip = rawValue.replace(/\D/g, '');
        if (strip.length > 11) {
            return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
        else {
            return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
        }
    };

    costCenterMark: boolean;
    costCenter = '';
    onEdit: boolean;
    changePassword: boolean;
    isPasswordShown: boolean;
    client: CognitoAttributes;
    user_sub: string;
    client_sub: string;
    loading: boolean;
    loadingMail: boolean;
    password: string;
    pageName: string;
    successEmail: boolean;
    loadingBlock: boolean;
    blockClientActive: boolean;
    blockPartnerActive = true;
    loadingSave: boolean;
    loadingCameras: boolean;
    hasCamera: boolean;
    hasRTMP: boolean;
    lastCamera: Camera;
    cameras: Camera[];
    childProfile: string;
    inventories: UserInventory;
    deleteLoading: Boolean;
    isMaster: Boolean;
    totalCamera = 0;

    isAssociate: boolean;
    adminAssociate: boolean;
    isPartner: boolean;

    constructor(private readonly store: Store<AppState>,
        private validationResponse: ValidationResponseHandlerModule,  private service: SharedService, private router: Router,
        private matDialog: MatDialog) { }

    ngOnInit(): void {
        this.user_sub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.childProfile = localStorage.getItem('childProfile');
        this.isMaster = localStorage.getItem('profile_name') == '7d4e9f24-17e8-11ec-9621-0242ac130002';
        localStorage.setItem('activeTab', 'info');

        if (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3') {
            this.isAssociate = true;
        }
        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
        if (localStorage.getItem('profile_name') == 'cd343bfc-17e8-11ec-9621-0242ac130002') {
            this.isPartner = true;
        }

        this.costCenterMark = localStorage.getItem('cost_center_mark') == 'true';
        this.costCenter = localStorage.getItem('cost_center');
        this.clientForm.get('costCenterBond').setValue(false);
        this.onEdit = false;
        this.changePassword = false;
        this.isPasswordShown = false;
        this.loading = true;
        this.loadingMail = false;
        this.password = 'password';
        this.successEmail = false;
        this.pageName = 'client-view';
        this.deleteLoading = false;
        this.hasRTMP = false;

        if (this.isPartner || this.isAssociate) {
            // this.store.dispatch(CameraActions.get_cameras({
            //     user_sub: this.user_sub,
            //     child_sub: this.client_sub,
            //     short: true,
            //     protocol: 'RTMP',
            //     limit: 1,
            //     page: 1
            // }));

            // this.store.dispatch(CameraActions.get_cameras({
            //     user_sub: this.user_sub,
            //     child_sub: this.client_sub,
            //     short: true,
            //     boxes: true,
            //     limit: 6,
            //     page: 1
            // }));

            this.store.dispatch(CameraActions.get_cameras({
                user_sub: this.user_sub,
                child_sub: this.client_sub,
                short: true,
                boxes: false,
            }));
            this.loadingCameras = true;
        }

        this.getUserData();
        this.getUpdateResult();
        this.getUpdatePartnerReturn();
        this.getCameras();
        this.getUserInventory();

        this.swalDecisionSub = this.service.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });

        this.block_client_sub = this.blockClient$.subscribe(block_client => {
            if (block_client) {
                if (block_client.status === 2) {
                    this.validationResponse.validationResponseHandler(200, 'user', 'block-client-result', block_client.message);
                    this.loadingBlock = false;
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'block-client-result-fail', block_client.message);
                    this.store.dispatch(UserActions.cleanup());
                    this.loadingBlock = false;
                }
            }
        });

        this.delete_client_sub = this.deleteResult$.subscribe(delete_result => {
            if (delete_result) {
                if (delete_result.status !== 2) {
                    this.deleteLoading = false;
                }
            }
        });

        this.delete_partner_sub = this.deletePartner$.subscribe(delete_partner => {
            if (delete_partner) {
                if (delete_partner.status !== 2) {
                    this.deleteLoading = false;
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.resend_welcome_email_sub) {
            this.resend_welcome_email_sub.unsubscribe();
        }
        if (this.user_data_sub) {
            this.user_data_sub.unsubscribe();
        }
        if (this.block_client_sub) {
            this.block_client_sub.unsubscribe();
        }
        if (this.swalDecisionSub) {
            this.swalDecisionSub.unsubscribe();
        }
        if (this.edit_client) {
            this.edit_client.unsubscribe();
        }
        if (this.edit_partner) {
            this.edit_partner.unsubscribe();
        }
        if (this.updateSub) {
            this.updateSub.unsubscribe();
        }
        if (this.getCams) {
            this.getCams.unsubscribe();
        }
        if (this.userInventory) {
            this.userInventory.unsubscribe();
        }
        if (this.delete_client_sub) {
            this.delete_client_sub.unsubscribe();
        }
        if (this.delete_partner_sub) {
            this.delete_partner_sub.unsubscribe();
        }
        this.store.dispatch(CameraActions.cleanup());
        this.store.dispatch(UserActions.cleanup());
        this.store.dispatch(UserActions.delete_partner_clear());
        localStorage.removeItem('context');
    }

    goToList() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/users/client/view']);
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'blockClient') {
            if (returnResultSwal.isConfirmed) {
                this.loadingBlock = true;
                if (this.blockClientActive) {
                    this.store.dispatch(UserActions.block_client({
                        sub: this.user_sub,
                        child_sub: this.client_sub,
                        active: false
                    }));
                    this.blockClientActive = false;
                }
            }
        }
        if (returnResultSwal.methodCalled === 'unblockClient') {
            if (returnResultSwal.isConfirmed) {
                this.loadingBlock = true;
                this.store.dispatch(UserActions.block_client({
                    sub: this.user_sub,
                    child_sub: this.client_sub,
                    active: true
                }));
                this.blockClientActive = true;
            }
        }
        if (returnResultSwal.methodCalled === 'deleteClient') {
            if (returnResultSwal.isConfirmed) {
                this.deleteLoading = true;
            }
        }

        if (returnResultSwal.methodCalled === 'deletePartner') {
            if (returnResultSwal.isConfirmed) {
                this.deleteLoading = true;
            }
        }
    }

    getUserData() {
        if (this.user_data_sub) {
            this.user_data_sub.unsubscribe();
        }
        this.store.dispatch(UserActions.get_user_data({
            user_sub: this.user_sub,
            child_sub: localStorage.getItem('clientView')
        }));
        this.user_data_sub = this.userData$.subscribe(client_data => {
            if (client_data) {
                if (client_data.model === 'feedback') {
                    this.validationResponse.validationResponseHandler(400, 'user-summary', 'return-data-error', 'user.client_cognito_error', client_data.data);
                }
                else {
                    if (client_data.sub != this.user_sub) {
                        this.client = client_data;
                        this.loadFormData();
                        this.loading = false;
                        this.loadingSave = false;
                        this.cancelEdit();
                        this.blockClientActive = client_data.active;
                    }
                }
            }
        });
    }

    getCameras() {
        this.getCams = this.cameras$.subscribe(cameras => {
            this.loadingCameras = false;
            if (cameras && cameras.model === 'cameras') {
                let cam: any = cameras.cameras as PaginationCameras;
                this.totalCamera = this.totalCamera < cam.totalAmount ? cam.totalAmount : this.totalCamera;
                cam = cam.cameras;
                if (cam.length > 0) {
                    this.cameras = cam;
                    this.hasCamera = true;
                    for (let i = 0; i < this.cameras.length; i++) {
                        if (this.cameras[i].protocol == 'RTMP'){
                            this.hasRTMP = true;
                            this.lastCamera = this.cameras[i];
                        }
                    }
                } else {
                    this.cameras = cam;
                }
            }
        });
    }
    loadFormData() {
        const cpfCnpj = this.client['custom:CNPJ'] ? this.client['custom:CNPJ'] : this.client['custom:CPF'];
        this.clientForm.get('name').setValue(this.client.name);
        this.clientForm.get('cpforcnpj').setValue(cpfCnpj ? cpfCnpj : '');
        this.clientForm.get('phone').setValue(this.processPhone(this.client.phone_number ? this.client.phone_number : ''));
        this.clientForm.get('email').setValue(this.client.email);
        this.clientForm.get('panicButton').setValue(this.client.device);
        this.clientForm.get('monitoringButton').setValue(this.client.avva);
        this.clientForm.get('panicName').setValue(this.client.contactEmergency != 'None' ? this.client.contactEmergency : null);
        this.clientForm.get('panicPhone').setValue(this.client.phoneEmergency != 'None' ? this.client.phoneEmergency : null);
        this.clientForm.get('password').setValue(this.password);
        this.clientForm.get('contractLink').setValue(this.client.contract_link);
        this.clientForm.get('permissionPartner').setValue(!!this.client.partner_permission);
        this.clientForm.get('costCenter').setValue(this.client.cost_center);
        this.clientForm.get('costCenterBond').setValue(!!this.client.cost_center_mark);

        this.clientForm.get('password').disable();
    }

    edit() {
        if ((!this.isAssociate || this.adminAssociate) || (this.isMaster && this.childProfile == 'Parceiro')) {
            this.onEdit = true;
        }
    }

    cancelEdit() {
        this.onEdit = false;
        this.changePassword = false;
        this.loadFormData();
    }

    editPassword() {
        this.changePassword = true;
        this.clientForm.get('password').enable();
        this.clientForm.get('password').setValue(null);
    }

    showPassword() {
        this.isPasswordShown = !this.isPasswordShown;
    }

    checkPassword() {
        if (this.clientForm.get('confirm').value == '') {
            this.clientForm.get('confirm').setErrors({ 'required': true });
        } else if (this.clientForm.get('password').value === this.clientForm.get('confirm').value) {
            this.clientForm.get('confirm').setErrors(null);
        } else {
            this.clientForm.get('confirm').setErrors({ 'unequal': true });
        }
    }

    changePanicButton() {
        const cgo = this.clientForm.get('monitoringButton').value;
        if (!cgo) {
            this.clientForm.get('panicButton').setValue(false);
        }
    }

    save() {
        this.loadingSave = true;
        let errorPass = false;
        let errorMsg = 'Alguns parametros estão inválidos.';

        if (this.clientForm.get('cpforcnpj').invalid) {
            errorPass = true;
            errorMsg = 'CPF ou CNPJ inválido';
            this.clientForm.get('cpforcnpj').markAsTouched();
        }
        if (this.clientForm.get('phone').invalid && this.childProfile != 'Parceiro') {
            errorPass = true;
            errorMsg = 'Número de celular inválido';
            this.clientForm.get('phone').markAsTouched();
        }
        if (this.clientForm.get('email').invalid) {
            errorPass = true;
            errorMsg = 'E-mail inválido';
            this.clientForm.get('email').markAsTouched();
        }
        if (this.clientForm.get('name').invalid) {
            errorPass = true;
            errorMsg = 'Nome inválido';
            this.clientForm.get('name').markAsTouched();
        }
        if (this.clientForm.get('costCenter').invalid) {
            errorPass = true;
            errorMsg = 'Centro de custo é necessário';
            this.clientForm.get('costCenter').markAsTouched();
        }
        if (this.changePassword) {
            if (this.clientForm.get('confirm').value !== this.clientForm.get('password').value && this.clientForm.get('confirm').value !== '') {
                errorPass = true;
                errorMsg = 'Senhas não conferem';
                this.clientForm.get('confirm').markAsTouched();
            }
        }
        if (this.clientForm.get('panicName').invalid && this.clientForm.get('monitoringButton').value) {
            errorPass = true;
            errorMsg = 'Contato de emergência inválido';
            this.clientForm.get('panicName').markAsTouched();
        }
        if (this.clientForm.get('panicPhone').invalid && this.clientForm.get('monitoringButton').value) {
            errorPass = true;
            errorMsg = 'Celular de emergência inválido';
            this.clientForm.get('panicPhone').markAsTouched();
        }
        if (errorPass){
            this.validationResponse.validationResponseHandler(400, 'user-update', 'all-inputs-user', errorMsg);
        } else if (this.clientForm.invalid){
            if (this.clientForm.errors && (this.clientForm.get('confirm').value !== '' && this.clientForm.errors.passwordMatch != null)) {
                this.validationResponse.validationResponseHandler(400, 'user-update', 'all-inputs-user', errorMsg);
            }
        }

        if (!errorPass) {
            let numNoMask: string = this.clientForm.get('phone').value;
            numNoMask = numNoMask.replace(/\D/g, '');

            if (this.childProfile == 'Parceiro') {
                const cpfCnpj = this.clientForm.get('cpforcnpj').value;
                const address = this.client.address;

                const attributeList: CognitoAttributes = {
                    sub: localStorage.getItem('sub'),
                    child_sub: localStorage.getItem('clientView'),
                    name: this.clientForm.get('name').value,
                    email: this.clientForm.get('email').value,
                    phone_number: `${numNoMask}`,
                    contract_link: this.clientForm.get('contractLink').value,
                    partner_permission: this.clientForm.get('permissionPartner').value,
                    password: (this.changePassword ? this.clientForm.get('password').value : null),
                    address: address,
                    picture: 'undefined',
                    cost_center: this.clientForm.get('costCenter').value,
                    cost_center_mark: this.clientForm.get('costCenterBond').value === true
                };
                if (cpfCnpj.length > 11) {
                    attributeList['custom:CNPJ'] = cpfCnpj;
                } else {
                    attributeList['custom:CPF'] = cpfCnpj;
                }
                this.store.dispatch(UserActions.update_partner({
                    user_sub: localStorage.getItem('sub'),
                    cognitoAttr: attributeList
                }));
            } else {
                // tslint:disable-next-line: no-shadowed-variable
                const client: UpdateClient = {
                    sub: localStorage.getItem('sub'),
                    child_sub: localStorage.getItem('clientView'),
                    avva: this.clientForm.get('monitoringButton').value,
                    createDevice: this.clientForm.get('panicButton').value,
                    password: (this.changePassword ? this.clientForm.get('password').value : null),
                    name: this.clientForm.get('name').value,
                    email: this.clientForm.get('email').value,
                    telephone: `${numNoMask}`,
                    contactEmergency: this.clientForm.get('panicName').value,
                    phoneEmergency: this.clientForm.get('panicPhone').value,
                };
                this.store.dispatch(UserActions.edit_client({
                    edit_client: client
                }));

                this.updateUser();
            }
        } else {
            this.loadingSave = false;
        }
    }

    updateUser() {
        const cpfCnpj = this.clientForm.get('cpforcnpj').value;
        const address = this.client.address;
        let numNoMask: string = this.clientForm.get('phone').value;
        numNoMask = numNoMask.replace(/\D/g, '');

        const attributeList: CognitoAttributes = {
            sub: localStorage.getItem('sub'),
            child_sub: localStorage.getItem('clientView'),
            name: this.clientForm.get('name').value,
            email: this.clientForm.get('email').value,
            phone_number: `${numNoMask}`,
            address: address,
            picture: 'undefined',
            cost_center: this.costCenterMark ? this.costCenter : this.clientForm.get('costCenter').value,
            cost_center_mark: this.costCenterMark ? this.costCenterMark : this.clientForm.get('costCenterBond').value === true
        };
        if (cpfCnpj.length > 11) {
            attributeList['custom:CNPJ'] = cpfCnpj;
        }
        else {
            attributeList['custom:CPF'] = cpfCnpj;
        }

        this.store.dispatch(UserActions.update_user({
            user_sub: localStorage.getItem('sub'),
            cognitoAttr: attributeList
        }));
    }

    blockClient() {
        if (this.blockClientActive){
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-block-client', 'user.block_client');
        }
        else {
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-unblock-client', 'user.unblock_client');
        }
    }

    blockPartner() {
        if (this.blockClientActive){
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-block-partner', 'user.block_partner');
        }
        else {
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-unblock-partner', 'user.unblock_partner');
        }
    }

    getUpdateResult() {
        this.updateSub = this.upResult$.subscribe((result) => {
            this.edit_client = this.editClient$.subscribe((register_feedback) => {
                if (register_feedback && result) {
                    if (register_feedback.status === 2 && result.status === 2) {
                        this.loadingSave = false;
                        this.validationResponse.validationResponseHandler(200, 'user', 'edit-client-user', register_feedback.message);
                        this.store.dispatch(UserActions.cleanup());
                        this.store.dispatch(CameraActions.get_clients_group_clear());
                        this.loading = true;
                        setTimeout(() => {
                            this.getUserData();
                        }, 500);
                        this.cancelEdit();
                    } else if (register_feedback.status != 2 && result.status != 2) {
                        this.loadingSave = false;
                        this.validationResponse.validationResponseHandler(400, 'user', 'edit-client-user-fail', register_feedback.message);
                        this.store.dispatch(UserActions.cleanup());
                        this.store.dispatch(CameraActions.get_clients_group_clear());
                        this.cancelEdit();
                    } else {
                        if (this.childProfile == 'Cliente' || this.childProfile == 'Cliente de Parceiro') {
                            const msgWarning = (register_feedback.status != 2 || result.status != 2) ? 'Algumas informações não foram salvas com sucesso' : 'Erro inesperado ao salvar informações';
                            this.validationResponse.validationResponseHandler(202, 'user', 'edit-client-user-fail', msgWarning);
                        }
                        this.loadingSave = false;
                        this.store.dispatch(UserActions.cleanup());
                        this.store.dispatch(CameraActions.get_clients_group_clear());
                        this.cancelEdit();
                    }
                }
            });
        });
    }

    getUpdatePartnerReturn() {
        this.edit_partner = this.editPartner$.subscribe(register_feedback => {
            if (register_feedback) {
                if (register_feedback.status === 2) {
                    this.loadingSave = false;
                    this.validationResponse.validationResponseHandler(200, 'user', 'edit-client-user', register_feedback.message);
                    this.store.dispatch(UserActions.cleanup());
                    this.loading = true;
                    setTimeout(() => {
                        this.getUserData();
                    }, 500);
                    this.cancelEdit();
                } else {
                    this.loadingSave = false;
                    this.validationResponse.validationResponseHandler(400, 'user', 'edit-client-user-fail', register_feedback.message);
                    this.store.dispatch(UserActions.cleanup());
                    this.cancelEdit();
                }
            }
        });
    }

    deletePartner() {
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-partner', 'user.delete_partner');
    }

    deleteClient() {
        if (this.cameras) {
            if (this.cameras.length <= 5 ){
                if (this.hasRTMP == true){
                    this.rtmpWarning(this.lastCamera);
                }
                else {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-client', 'user.delete_client');
                }
            }
        }
        else {
            this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-client', 'user.delete_client');
        }
    }

    processPhone(initial_number: string) {
        let finalNumber = '';
        if (initial_number.includes('(')) {
            finalNumber = initial_number;
        } else {
            if (initial_number.length == 14 || initial_number.length == 13) {
                initial_number = initial_number.slice(3, initial_number.length);
            }
            if (initial_number.length == 11 || initial_number.length == 10) {
                let firstRange;
                if (initial_number.length == 11) {
                    firstRange = 7;
                } else {
                    firstRange = 6;
                }
                initial_number = '(' + initial_number.slice(0, 2) + ') ' + initial_number.slice(2, firstRange)
          + '-' + initial_number.slice(firstRange, initial_number.length);
            }
            finalNumber = initial_number;
        }
        return finalNumber;
    }

    validateEmergencyNumber() {
        if (this.clientForm.get('panicPhone').value == '' && this.clientForm.get('monitoringButton')) {
            this.clientForm.get('panicPhone').setErrors({'required': true});
        } else {
            this.clientForm.get('panicPhone').setErrors(null);
        }
    }

    validateEmergencyContact() {
        if (this.clientForm.get('panicName').value == '' && this.clientForm.get('monitoringButton')) {
            this.clientForm.get('panicName').setErrors({'required': true});
        } else {
            this.clientForm.get('panicName').setErrors(null);
        }
    }

    resendEmail() {
        this.loadingMail = true;
        this.store.dispatch(UserActions.resend_welcome_email({
            sub: this.user_sub,
            child_sub: this.client_sub
        }));
        this.resend_welcome_email_sub = this.resendWelcomeEmail$.subscribe(email_feedback => {
            if (email_feedback) {
                if (email_feedback.status === 2) {
                    this.successEmail = true;
                    setTimeout(() => {
                        this.successEmail = false;
                    }, 3000);
                    this.loadingMail = false;
                } else {
                    this.validationResponse.validationResponseHandler(400, 'user', 'alert-resend-email-fail', email_feedback.message);
                    this.loadingMail = false;
                }
            }
        });
    }

    rtmpWarning(data: Camera): void {
        this.matDialog.open(UserRtmpWarningComponent, {
            width: '75%' ,
            height: '75%' ,
            data: data ,
        });
    }

    getUserInventory() {
        this.store.dispatch(UserActions.get_user_inventory({
            user_sub: this.user_sub
        }));
        this.userInventory = this.userInventory$.subscribe(inventory => {
            if (inventory) {
                this.inventories = inventory[0];
            }
        });
    }

    addClient() {
        const dataModal = {
            data: {
                cameras_avaiable: this.inventories.inventory.ci.total.available,
                analytics_avaiable: this.inventories.inventory.ai.available,
                kits: this.inventories.kits,
                childProfile: this.childProfile,
                guestProfile: this.client.child_profile,
                plans: this.inventories.plans
            },
            tipoModal: 'adicionar_cliente',
            modalHeight: '50vh',
            modalWidth: '50vw'
        };
        this.service.returnDataToModal(dataModal);
    }

    openHolidayView() {
        const dataModal = {
            data : {
                client_name: this.client.name,
                cameras: this.cameras
            },
            modalHeight: '90vh',
            modalWidth: '80vw',
            backdropClass: 'lightBackdrop',
            tipoModal: 'configurar_calendario'
        };
        this.service.returnDataToModal(dataModal);
    }
}

