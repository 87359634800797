<div class="timer-component" [class.timer-component-calendar]="calendarSelect">
    <div [class.time-icon]="!calendarSelect" [class.time-icon-calendar]="calendarSelect">
        <i nz-icon nzType="clock-circle" nzTheme="outline" (click)="openTimePicker()"></i>
    </div>
    <div class="timePicker" [class.timePicker-calendar]="calendarSelect" [class.timePicker-table]="calendarTable" *ngIf="isOpen">
        <div id='startTime'>
            <div #startTime class="hours">
                <div class="hour" *ngFor="let item of hour" [innerHTML]="item"
                    [ngClass]="item === startHour ? 'selected' : 'unselected'"
                    (click)="startHour = item; changeValue()"></div>
            </div>
            <div class="minutes">
                <div class="minute" *ngFor="let item of minute" [innerHTML]="item"
                    [ngClass]="item === startMinute ? 'selected' : 'unselected'"
                    (click)="startMinute = item; changeValue()"></div>
            </div>
        </div>
        <div id='endTime'>
            <div #endTimeHour class="hours">
                <div class="hour"
                    *ngFor="let item of hour"
                    [innerHTML]="item"
                    [ngClass]="{
                        'selected': item === endHour,
                        'unselected': item !== endHour && !isHourDisabled(item),
                        'disabled': isHourDisabled(item)
                    }"
                    (click)="onEndHourClick(item)">
                </div>
            </div>
            <div #endTimeMinute class="minutes">
                <div class="minute" *ngFor="let item of minute"
                    [innerHTML]="item"
                    [ngClass]="{
                        'selected': item === endMinute,
                        'unselected': item !== endMinute && !isMinuteDisabled(item),
                        'disabled': isMinuteDisabled(item)
                    }"
                    (click)="onEndMinuteClick(item)"></div>
            </div>
        </div>
    </div>
</div>