<div class="flex justify-between title-header">
  <span class="title-component" *ngIf="isPartner && context !== 'summary'">Lista de usuários da conta</span>
  <span class="title-component" *ngIf="!isPartner || context === 'summary'">Lista de usuários da conta</span>
  <button (click)="openModalAddGuest()" class="btn btn-ant enter-button" *ngIf="isPartner || adminAssociate">
    <i nz-icon nzType="plus" nzTheme="outline" class="prefix"></i>
    ADICIONAR USUÁRIO
  </button>
</div>

<div style="width: 97%;">
  <table mat-table [dataSource]="dataSource" class="style-table" *ngIf="!loading && dataSource.data.length !== 0">
    <!-- Nome do convidado -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Nome</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} <span *ngIf="!element.full_created" class="pedding_invite">Pendente</span> </td>
    </ng-container>
    <!-- Email do convidado -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>E-mail</th>
      <td mat-cell *matCellDef="let element"> <span *ngIf="element.full_created">{{element.email}}</span> </td>
    </ng-container>
    <!-- Telefone do convidado -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Telefone</th>
      <td mat-cell *matCellDef="let element"> <span *ngIf="element.full_created">{{element.phone_number | phone}}</span> </td>
    </ng-container>
    <!-- Detalhes e Botão de reenviar-->
    <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef id="header-details"></th>
      <td mat-cell *matCellDef="let element; let index = index">
        <ng-container *ngIf="(!element.full_created && !element.loadingResend && (isPartner || adminAssociate))">
          <button class="delete-button" (click)="resendInvite(element)" [disabled]="!(isPartner || adminAssociate)">
          <i nz-icon nz-popover nzPopoverContent="Reenviar convite" nzType="mail" nzTheme="outline" class="delete-icon"></i>
          </button>
        </ng-container>
        <span nz-icon nzType="loading" nzTheme="outline" *ngIf="element.loadingResend" class="posfix"></span>
        <button *ngIf="element.full_created" (click)="openModalGuestSettings(index)" mat-raised-button class="btn btn-sm btn-just-icon btn-round btn-settings">
          <i nz-icon nzType="setting" nzTheme="outline"></i>
        </button>
      </td>
    </ng-container>
    <!-- Botão de excluir -->
    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef id="header-delete"></th>
      <td mat-cell *matCellDef="let element; let index = index">
        <ng-container *ngIf="!element.full_created && !loading && (isPartner || adminAssociate)">
          <button class="delete-button" (click)="deleteAssociated(index)">
            <i nz-icon nz-popover nzPopoverContent="Deletar convidado" nzType="delete" nzTheme="outline" class="delete-icon"></i>
        </button>
        </ng-container>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="dataSource.data.length === 0 && !loading">
    <app-feedback imageUrl="no_client.svg" [transparent]="true" [noUsers]="true" type="usuários da conta"></app-feedback>
  </div>

</div>

<div *ngIf="loading" class="loading_container">
  <span nz-icon class="center" nzType="loading" nzTheme="outline" style="font-size:35px"></span>
</div>

<app-user-invite-guests (loadAddGuestEmitter)="upUserAddGuest($event)"></app-user-invite-guests>
<app-user-guest-settings (loadGuestSettingsEmitter)="upUserGuestSettings($event)" ></app-user-guest-settings>
