import { ValidationResponseHandlerModule } from 'app/Shared/ValidationResponse/validation-response-handler';
import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { CameraActions } from './../Services/actions';
import { SharedService } from 'app/Shared/Services/shared.service';
import { AddInfoGroupData, AssociatedsInfoGroup, Camera, ClientsInfoGroup, GroupCamera } from '../models';
import { Associate } from 'app/Login/models';
import { AppState } from 'app/store/model';
import { ResultAlert } from './../../Shared/models';

@Component({
    selector: 'app-camera-group-config',
    templateUrl: './camera-group-config.component.html',
    styleUrls: ['./camera-group-config.component.scss']
})
export class CameraGroupConfigComponent implements OnInit, OnDestroy {
    readonly groupCameraID$ = this.store.select((state: AppState) => state.camera.groupCameraID);
    readonly deleteCameraGroup$ = this.store.select((state: AppState) => state.camera.deleteCamGroup);
    readonly updateCameraGroup$ = this.store.select((state: AppState) => state.camera.updateCameraGroup);

    updateCameraGroupSub: Subscription;
    swalSub: Subscription;
    destroy = new Subject<any>();

    @Input() context: string;
    @Output() modalClose = new EventEmitter<string>();
    displayedColumns: string[] = ['camera', 'client', 'select'];
    dataSource: MatTableDataSource<Camera> = new MatTableDataSource([] as Camera[]);
    dataSourceTwo: MatTableDataSource<ClientsInfoGroup> = new MatTableDataSource([] as ClientsInfoGroup[]);
    dataSourceThree: MatTableDataSource<AssociatedsInfoGroup> = new MatTableDataSource([] as AssociatedsInfoGroup[]);

    deleteCamGroupSub: Subscription;
    getGroupID: Subscription;
    group_id: number;
    isPartner: boolean;
    loadingList: boolean;

    pageName: string;
    sub: string;
    client_sub: string;
    associate: Associate;
    isAssociate: boolean;
    adminAssociate: boolean;
    adminClientAssociate: boolean;
    isClient: boolean;
    isClientAssociate: boolean = localStorage.getItem('profile_name') === '419bea06-5d4e-4a56-b8b5-04b3ad566d59';

    groupName: string;
    activeTab = 'group';
    groupCamera: GroupCamera;
    blockDelete: boolean;
    loading: boolean;
    loadingName: boolean;
    hasGroups: boolean;
    selectingCameras: boolean;
    selectingClients: boolean;
    selectingAssociates: boolean;
    allSelectedCameras: boolean;
    allSelectedClients: boolean;
    allSelectedAssociates: boolean;
    noneCameraSelected = true;
    noneClientSelected = true;
    noneAssociateSelected = true;
    listDeleteCamera: number[];
    listDeleteClient: number[];
    listDeleteAssociate: number[];

    name = new UntypedFormControl('', [Validators.required,  Validators.minLength(3)]);

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private readonly store: Store<AppState>, private validationResponse: ValidationResponseHandlerModule,
        private sharedService: SharedService, private router: Router
    ) { }

    ngOnInit(): void {
        this.context = localStorage.getItem('context');
        this.pageName = 'cameras-group';
        this.sub = localStorage.getItem('sub');
        this.client_sub = localStorage.getItem('clientView');
        this.associate = JSON.parse(localStorage.getItem('associate'));
        this.adminClientAssociate = this.isClientAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002';
        this.isPartner = (localStorage.getItem('profile_name') === 'cd343bfc-17e8-11ec-9621-0242ac130002');
        this.isAssociate = (localStorage.getItem('profile_name') === 'f29868c7-b4c5-4963-9ae8-1dd95699d6c3');

        if (this.isAssociate && localStorage.getItem('associate_permission') === 'e816c560-812e-11ed-a1eb-0242ac120002') {
            this.adminAssociate = true;
        }
        if (this.context == 'camera-list') {
            this.isClient = true;
        }
        else {
            this.isClient = false;
        }
        this.activeTab = 'group';
        this.loadingList = false;
        this.blockDelete = true;
        this.loading = false;
        this.groupCamera = this.data.data.cameraGroup;
        this.groupName = this.groupCamera.alias;
        this.group_id = this.groupCamera.id_camera_group;
        this.name.setValue(this.groupCamera.alias);

        this.getGroupCameraDispatch();
        this.getGroupCameras();
        this.updateCameraGroupResult();
        this.confirmEditCameraGroup();
    }

    ngOnDestroy(): void {
        this.dataSource.data.map(x => x.select = false);
        this.dataSourceTwo.data.map(x => x.select = false);
        this.dataSourceThree.data.map(x => x.select = false);
        this.selectingAssociates = false;
        this.selectingCameras = false;
        this.selectingClients = false;

        if (this.swalSub) {
            this.swalSub.unsubscribe();
        }
        if (this.updateCameraGroupSub) {
            this.updateCameraGroupSub.unsubscribe();
        }
        if (this.deleteCamGroupSub) {
            this.deleteCamGroupSub.unsubscribe();
        }
        if (this.getGroupID) {
            this.getGroupID.unsubscribe();
        }
        this.store.dispatch(CameraActions.delete_group_cameras_clear());
        this.store.dispatch(CameraActions.update_camera_group_clear());
        this.store.dispatch(CameraActions.clear_group_cameras());
        this.closeModal();
    }

    closeModal() {
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: localStorage.getItem('sub'),
            child_sub: this.isClient ? localStorage.getItem('clientView') : null,
            type_request: this.isClient ? 'data' : 'list'
        }));
        this.destroy.next(true);
        // this.reload();
        this.modalClose.emit();
    }

    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([this.router.url]);
    }

    getGroupCameras() {
        this.getGroupID = this.groupCameraID$.subscribe(result => {
            if (result) {
                this.groupCamera = result[0];
                this.groupName = this.groupCamera.alias;
                this.group_id = this.groupCamera.id_camera_group;
                this.dataSource.data = this.groupCamera.cameras_info_group.cameras;
                this.dataSourceTwo.data = this.groupCamera.clients_info_group;
                this.dataSourceThree.data = this.groupCamera.associateds_info_group !== null ? this.groupCamera.associateds_info_group : [];
                this.hasGroups = this.groupCamera.cameras_info_group.cameras.length > 0;
                this.loadingList = false;
                this.activeTab = 'group';
            }
        });
    }

    getGroupCameraDispatch() {
        this.loadingList = true;
        this.store.dispatch(CameraActions.get_group_cameras({
            user_sub: this.sub,
            child_sub: this.isClient ? this.client_sub : null,
            group_id: this.group_id,
            type_request: 'list'
        }));
    }

    deleteCamGroup() {
        this.blockDelete = false;
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-camera-group', 'camerasGroups.delete_camera_group');
    }

    deleteCameraGroupResult() {
        this.deleteCamGroupSub = this.deleteCameraGroup$.subscribe(result => {
            if (result) {
                this.loading = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'delete_camera_group_success', result.message);
                    this.store.dispatch(CameraActions.clear_group_cameras());
                    this.closeModal();
                    setTimeout(() => { this.reload(); }, 800);
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'delete_camera_group_error', result.message);
                }
            }
        });
    }

    changeTab(event) {
        this.dataSource.data.map(x => x.select = false);
        this.dataSourceTwo.data.map(x => x.select = false);
        this.dataSourceThree.data.map(x => x.select = false);
        this.selectingAssociates = false;
        this.selectingCameras = false;
        this.selectingClients = false;
        const tabs = document.querySelectorAll('.btn-tab');
        tabs.forEach(element => {
            element.classList.remove('active');
        });
        this.activeTab = event.currentTarget.id;
        event.currentTarget.classList.add('active');
    }

    addInGroup() {
        const modalContext: AddInfoGroupData = {
            id: this.group_id,
            name: this.groupName,
            mode: this.activeTab,
            associate_list: this.groupCamera.associateds_info_group.map(element => element.id_user),
            client_list: this.groupCamera.clients_info_group.map(element => element.id_user),
            camera_list: this.groupCamera.cameras_info_group.cameras.map(element => element.id)
        };
        const dataModal = {
            data: modalContext,
            modalHeight: '90vh',
            modalWidth: '85vw',
            backdropClass: 'lightBackdrop',
            tipoModal: 'adicionar_info_grupo'
        };
        this.sharedService.returnDataToModal(dataModal);
    }

    checkSelecteds(data: MatTableDataSource<any>) {
        const dataSelected = data.data.filter(x => x.select);
        if (dataSelected.length == data.data.length) {
            this.allSelectData(data, true);
        } else if (dataSelected.length == 0) {
            this.allSelectData(data, false);
        } else {
            if (this.activeTab === 'cameras') {
                this.noneCameraSelected = false;
            } else if (this.activeTab === 'clients') {
                this.noneClientSelected = false;
            } else if (this.activeTab === 'associates') {
                this.noneAssociateSelected = false;
            }
        }
    }

    allSelectData(dataTable: MatTableDataSource<any>, selected: boolean) {
        if (this.activeTab === 'cameras') {
            this.allSelectedCameras = selected;
            this.noneCameraSelected = !selected;
        } else if (this.activeTab === 'clients') {
            this.allSelectedClients = selected;
            this.noneClientSelected = !selected;
        } else if (this.activeTab === 'associates') {
            this.allSelectedAssociates = selected;
            this.noneAssociateSelected = !selected;
        }
        dataTable.data.map(x => x.select = selected);
    }

    updateCameraGroup() {
        this.blockDelete = false;
        let title = '';
        let namesToText = '';
        this.listDeleteCamera = [];
        this.listDeleteClient = [];
        this.listDeleteAssociate = [];
        if (this.activeTab === 'cameras') {
            title = 'câmeras';
            const dataSelected = this.dataSource.data.filter(x => x.select);
            this.listDeleteCamera = dataSelected.map(x => x.id);
            namesToText = dataSelected.map(x => x.alias).join(', ');
        } else if (this.activeTab === 'clients') {
            title = 'clientes';
            const dataSelected = this.dataSourceTwo.data.filter(x => x.select);
            this.listDeleteClient = dataSelected.map(x => x.id_user);
            namesToText = dataSelected.map(x => x.name).join(', ');
        } else if (this.activeTab === 'associates') {
            title = 'convidados';
            const dataSelected = this.dataSourceThree.data.filter(x => x.select);
            this.listDeleteAssociate = dataSelected.map(x => x.id_user);
            namesToText = dataSelected.map(x => x.name).join(', ');
        }
        const swalParameters = {
            title: 'Remover ' + title + ' do grupo "' + this.groupName + '"?',
            text: 'Remover ' + title + ': ' + namesToText + '.',
            confirmButton: 'Remover',
            cancelButton: 'Cancelar'
        };
        this.validationResponse.validationResponseHandler(200, this.pageName, 'confirm-delete-item', 'camerasGroups.delete_cameras', swalParameters);
    }

    confirmEditCameraGroup() {
        this.swalSub = this.sharedService.swalDecision.subscribe(returnSwallObject => {
            if (returnSwallObject) {
                this.validateReturnMethodCalled(returnSwallObject as ResultAlert);
            }
        });
    }

    validateReturnMethodCalled(returnResultSwal: ResultAlert) {
        if (returnResultSwal.methodCalled === 'updateCameraGroup') {
            if (returnResultSwal.isConfirmed && !this.blockDelete) {
                const payload = {
                    user_sub: this.sub,
                    child_sub: null,
                    listDeleteCameras: this.listDeleteCamera !== undefined ? this.listDeleteCamera : [],
                    listDeleteClients: this.listDeleteClient !== undefined ? this.listDeleteClient : [],
                    listDeleteAssociates: this.listDeleteAssociate !== undefined ? this.listDeleteAssociate : [],
                    listAddCameras: [],
                    listAddClients: [],
                    listAddAssociates: [],
                    nameCameraGroup: this.groupName,
                    group_id: this.group_id,
                };
                this.store.dispatch(CameraActions.update_camera_group(payload));
                this.loading = true;
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
        if (returnResultSwal.methodCalled === 'deleteCamGroup') {
            if (returnResultSwal.isConfirmed && !this.blockDelete) {
                this.store.dispatch(CameraActions.delete_group_cameras({
                    user_sub: this.sub,
                    group_id: this.group_id,
                }));
                this.loading = true;
                this.deleteCameraGroupResult();
            }
            else if (returnResultSwal.isDismissed) {
            }
        }
    }

    updateCameraGroupResult() {
        this.updateCameraGroupSub = this.updateCameraGroup$.subscribe(result => {
            if (result) {
                this.loading = false;
                this.loadingName = false;
                if (result.status === 2) {
                    this.validationResponse.validationResponseHandler(200, this.pageName, 'delete-item-success', 'camerasGroups.update_success');
                    this.loadingList = false;
                    this.store.dispatch(CameraActions.get_group_cameras({
                        user_sub: localStorage.getItem('sub'),
                        child_sub: this.isClient ? this.client_sub : null,
                        type_request: this.isClient ? 'data' : 'list'
                    }));
                    this.getGroupCameraDispatch();
                } else {
                    this.validationResponse.validationResponseHandler(400, this.pageName, 'delete-item-error', result.message);
                }
            }
        });
    }

    changeName() {
        if (this.name.invalid) {
            this.name.markAsTouched();
            this.name.setErrors({
                required: true
            });
            return;
        }

        this.loadingName = true;

        const payload = {
            nameCameraGroup: this.name.value,
            group_id: this.group_id,
            user_sub: this.sub
        };

        this.store.dispatch(CameraActions.update_camera_group(payload));
        this.updateCameraGroupResult();
    }

    cancelNameEdit() {
        this.name.setValue(this.groupName);
    }
}
