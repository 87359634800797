<div style="padding: 55px 34px 4px 25px;height: 600px;overflow: auto;">
  <div class="modal-container">
    <div class="flex justify-between items-center">
      <span class="title-modal" *ngIf="!isPublic">Criar Grupo de Câmeras Privado</span>
      <span class="title-modal" *ngIf="isPublic">Criar Grupo de Câmeras Público</span>
      <div (click)="closeModal()" class="close-modal">
        <i nz-icon nzType="close" nzTheme="outline"></i>
      </div>
    </div>
    
    <form [formGroup]="groupForm" *ngIf="!loadingClients; else loadingTemplate">
      <div navBarLayout="empty-symbols" id="pressEnter">
        <div *ngIf="currentStep === 0">
          <div class="column-1 columns">
            <div class="label-container">
              <div class="label-name">
                <span>Nome do grupo :</span>
              </div>
              <input nz-input type="text" placeholder="Digite aqui..." formControlName="name"
               class="input-container" />
              <mat-error *ngIf="groupForm.get('name').hasError('required') && groupForm.get('name').touched">
                Nome do grupo <strong>é requerido</strong>
              </mat-error>
            </div>
  
            <div class="label-container" [class.blocked]="groupForm.get('client').invalid">
              <div class="label-name">
                <span>Usuários da conta :</span>
              </div>
              <app-filter-list
                [styles]="['multiselect-alternate-style']"
                *ngIf="!loadingFilter"
                placeholder="Selecionar usuários da conta"
                type="multiselect"
                [list]="associateSimple"
                [blocked]="groupForm.get('client').invalid"
                [search]="true"
                selectAllIcon="usergroup-add"
                selectAll="Adicionar todos os usuários"
                (filterListStatusChange)="changeAssociate($event)">
              </app-filter-list>
              <mat-error class="mat_error" style="bottom: 11em"
              *ngIf="groupForm.get('associate').hasError('required') && groupForm.get('associate').touched">
                Selecionar um usuário da conta <strong>é requerido</strong>
              </mat-error>
            </div>
          </div>
  
          <div class="column-2 columns">
            <div class="label-container">
              <div class="label-name">
                <span>Cliente :</span>
              </div>
              <div class="selector-container">
                <app-filter-list
                [styles]="['multiselect-alternate-style']"
                *ngIf="!loadingClients"
                placeholder="Pesquisar clientes..."
                [type]="isPublic ? 'multiselect' : 'simple'"
                [list]="clientSimple"
                [search]="true"
                (filterListStatusChange)="changeClient($event)"></app-filter-list>
              </div>
              <mat-error class="mat_error" style="top: 13em"
              *ngIf="groupForm.get('client').hasError('required') && groupForm.get('client').touched">
                Selecionar um cliente <strong>é requerido</strong>
              </mat-error>
            </div>
          </div>
  
          <div class="flex next-buttons">
            <i class="material-icons steps-left-style">fiber_manual_record</i>
            <i (click)="goToNext()"
              class="material-icons-outlined steps-right-style">fiber_manual_record</i>
            <button class="btn btn-primary enter-button" (click)="goToNext()">
              Próximo
            </button>
          </div>
        </div>
        <div *ngIf="currentStep === 1">
          <div class="column-3 columns">
            <div class="label-container" [class.blocked]="groupForm.get('client').invalid">
              <div class="label-name label-camera">
                <span>Câmeras :</span>
                <span class="clickable info" *ngIf="!isPublic" nz-icon nzType="info-circle" nz-tooltip
                nzTooltipTitle="Ao criar um grupo privado, os usuários de conta presentes no grupo perderão acesso a todas as câmeras que 
                forem compartilhadas anteriormente no seu vínculo com o cliente, ficando apenas as que estão presentes no grupo de câmera criado" 
                nzTooltipOverlayClassName="camera-link-rtsp" nzTooltipPlacement="right" nzTheme="outline"></span>
              </div>
              <app-filter-list
                [styles]="['multiselect-alternate-style']"
                *ngIf="!loadingFilter"
                placeholder="Pesquisar câmeras..."
                type="multiselect"
                [list]="cameraSimple"
                [search]="true"
                (filterListStatusChange)="changeCamera($event)"
                selectAllIcon="video-camera-add"
                selectAll="Adicionar todas as câmeras dos clientes vinculados ao grupo">
              </app-filter-list>
              <mat-error class="mat_error" style="top: 15em"
              *ngIf="groupForm.get('camera').hasError('required') && groupForm.get('camera').touched">
                Selecionar uma câmera <strong>é requerido</strong>
              </mat-error>
            </div>
          </div>
          
          <div class="flex next-buttons">
            <i (click)="goToPrevious()"
            class="material-icons-outlined steps-left-style">fiber_manual_record</i>
            <i class="material-icons steps-right-style">fiber_manual_record</i>
            <button class="btn btn-primary btn-ant enter-button" (click)="createGroup()" [disabled]="loadingSubmit">
              Criar
              <i nz-icon nzType="loading" nzTheme="outline" class="posfix" *ngIf="loadingSubmit"></i>
            </button>
          </div>
        </div>
      </div>
    </form>  
  </div>
  
  <ng-template #loadingTemplate>
    <div class="loadingModal">
        <span nz-icon nzType="loading" nzTheme="outline"></span>
    </div>
  </ng-template>
</div>