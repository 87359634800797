import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    private currentPage: string;

    constructor(private analytics: AngularFireAnalytics, private router: Router) {
    }

    private trackPageViews() {
        console.log('trackPageViews');
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                console.log(event);
                this.currentPage = event.urlAfterRedirects; // Store the current page URL
                this.logEvent('page_view', { page: this.currentPage });
            });
    }

    logEvent(eventName: string, eventParams: { [key: string]: any } = {}): void {
    // Add current page to event parameters

        this.analytics.logEvent(eventName, {
            'metadata': JSON.stringify({ ...eventParams }),
            'page': this.router.url,
            'date': new Date()
        })
            .then(() => console.log(`Event logged: ${eventName}`, eventParams))
            .catch(error => console.error(`Error logging event: ${eventName}`, error));
    }
}
